/* eslint-disable */
import React, { JSX } from "react";
import PersonAquariusIcon from "../assets/svg/horoscope-person-aquarius";
import PersonAriesIcon from "../assets/svg/horoscope-person-aries";
import PersonCancerIcon from "../assets/svg/horoscope-person-cancer";
import PersonCapricornIcon from "../assets/svg/horoscope-person-capricorn";
import PersonTaurusIcon from "../assets/svg/horoscope-person-taurus";
import PersonGeminiIcon from "../assets/svg/horoscope-person-gemini";
import PersonLeoIcon from "../assets/svg/horoscope-person-leo";
import PersonVirgoIcon from "../assets/svg/horoscope-person-virgo";
import PersonLibraIcon from "../assets/svg/horoscope-person-libra";
import PersonScorpioIcon from "../assets/svg/horoscope-person-scorpio";
import PersonSagittariusIcon from "assets/svg/horoscope-person-sagittarius";
import PersonPiscesIcon from "../assets/svg/horoscope-person-pisces";

import CapricornIcon from "assets/svg/zodiacs/capricorn-icon.svg";
import AquariusIcon from "assets/svg/zodiacs/aquarius-icon.svg";
import PiscesIcon from "assets/svg/zodiacs/pisces-icon.svg";
import AriesIcon from "assets/svg/zodiacs/aries-icon.svg";
import TaurusIcon from "assets/svg/zodiacs/taurus-icon.svg";
import GeminiIcon from "assets/svg/zodiacs/gemini-icon.svg";
import CancerIcon from "assets/svg/zodiacs/cancer-icon.svg";
import LeoIcon from "assets/svg/zodiacs/leo-icon.svg";
import VirgoIcon from "assets/svg/zodiacs/virgo-icon.svg";
import LibraIcon from "assets/svg/zodiacs/libra-icon.svg";
import ScorpioIcon from "assets/svg/zodiacs/scorpio-icon.svg";
import SagittariusIcon from "assets/svg/zodiacs/sagittarius-icon.svg";

interface ZodiacSign {
  dateMin: string;
  dateMax: string;
  type: string;
  data: {
    zodiacIconUrl: string;
    icon: () => JSX.Element;
    text: () => JSX.Element;
  };
}
export const zodiacSigns: Record<string, ZodiacSign> = {
  Capricorn: {
    dateMin: "2000/12/22",
    dateMax: "2000/01/20",
    type: "Capricorn",
    data: {
      zodiacIconUrl: CapricornIcon,
      icon: () => <PersonCapricornIcon />,
      text: () => (
        <p className="text">
          <span className={"gradient-pink-text"}>Capricorn</span>, your 
          discipline and ambition are your greatest tools. Aim high and stay 
          focused on your goals
        </p>
      ),
    },
  },
  Aquarius: {
    dateMin: "2000/01/21",
    dateMax: "2000/02/18",
    type: "Aquarius",
    data: {
      zodiacIconUrl: AquariusIcon,
      icon: () => <PersonAquariusIcon />,
      text: () => (
        <p className="text">
          Innovation is the key for
          <span className={"gradient-pink-text"}>Aquarius</span> today. Think outside
          the box and your unique perspective will bring breakthroughs.
        </p>
      ),
    },
  },
  Pisces: {
    dateMin: "2000/02/19",
    dateMax: "2000/03/20",
    type: "Pisces",
    data: {
      zodiacIconUrl: PiscesIcon,
      icon: () => <PersonPiscesIcon />,
      text: () => (
        <p className="text">
          Adventure calls to <span className={"gradient-pink-text"}>Pisces</span>, let
          your creativity flow. Your artistic talents will bring joy and
          inspiration to those around you.
        </p>
      ),
    },
  },
  Aries: {
    dateMin: "2000/03/21",
    dateMax: "2000/04/19",
    type: "Aries",
    data: {
      zodiacIconUrl: AriesIcon,
      icon: () => <PersonAriesIcon />,
      text: () => (
        <p className="text">
          And remember, today's horoscope advises
          <span className={"gradient-pink-text"}> Aries</span> to trust their instincts
          and take bold strides. Seize the day with confidence!
        </p>
      ),
    },
  },
  Taurus: {
    dateMin: "2000/04/20",
    dateMax: "2000/05/20",
    type: "Taurus",
    data: {
      zodiacIconUrl: TaurusIcon,
      icon: () => <PersonTaurusIcon />,
      text: () => (
        <p className="text">
          Today, <span className={"gradient-pink-text"}>Taurus</span> should focus on
          stability. Slow and steady wins the race. Patience will be your
          greatest asset.
        </p>
      ),
    },
  },
  Gemini: {
    dateMin: "2000/05/21",
    dateMax: "2000/06/20",
    type: "Gemini",
    data: {
      zodiacIconUrl: GeminiIcon,
      icon: () => <PersonGeminiIcon />,
      text: () => (
        <p className="text">
          <span className={"gradient-pink-text"}>Gemini</span>, your curiosity is your
          strength today. Engage in conversations and explore new ideas.
          Communication opens doors!
        </p>
      ),
    },
  },
  Cancer: {
    dateMin: "2000/06/21",
    dateMax: "2000/07/22",
    type: "Cancer",
    data: {
      zodiacIconUrl: CancerIcon,
      icon: () => <PersonCancerIcon />,
      text: () => (
        <p className="text">
          <span className={"gradient-pink-text"}>Cancer</span>, nurturing your personal
          relationships is key today. Your empathy and understanding will bring
          warmth to those around you.
        </p>
      ),
    },
  },
  Leo: {
    dateMin: "2000/07/23",
    dateMax: "2000/08/22",
    type: "Leo",
    data: {
      zodiacIconUrl: LeoIcon,
      icon: () => <PersonLeoIcon />,
      text: () => (
        <p className="text">
          <span className={"gradient-pink-text"}>Leo</span>, let your confidence shine.
          Take the lead in projects and your charisma will inspire others to
          follow.
        </p>
      ),
    },
  },
  Virgo: {
    dateMin: "2000/08/23",
    dateMax: "2000/09/22",
    type: "Virgo",
    data: {
      zodiacIconUrl: VirgoIcon,
      icon: () => <PersonVirgoIcon />,
      text: () => (
        <p className="text">
          Attention to detail will serve
          <span className={"gradient-pink-text"}>Virgo</span> well today. Organize and
          plan; your meticulous nature will lead to success.
        </p>
      ),
    },
  },
  Libra: {
    dateMin: "2000/09/23",
    dateMax: "2000/10/22",
    type: "Libra",
    data: {
      zodiacIconUrl: LibraIcon,
      icon: () => <PersonLibraIcon />,
      text: () => (
        <p className="text">
          <span className={"gradient-pink-text"}>Libra</span>, seek balance in your
          interactions. Your ability to mediate will resolve conflicts and bring
          harmony.
        </p>
      ),
    },
  },
  Scorpio: {
    dateMin: "2000/10/23",
    dateMax: "2000/11/21",
    type: "Scorpio",
    data: {
      zodiacIconUrl: ScorpioIcon,
      icon: () => <PersonScorpioIcon />,
      text: () => (
        <p className="text">
          <span className={"gradient-pink-text"}>Scorpio</span>, trust your intuition.
          Your insights will guide you through any challenges that arise today.
        </p>
      ),
    },
  },
  Sagittarius: {
    dateMin: "2000/11/22",
    dateMax: "2000/12/21",
    type: "Sagittarius",
    data: {
      zodiacIconUrl: SagittariusIcon,
      icon: () => <PersonSagittariusIcon />,
      text: () => (
        <p className="text">
          Adventure calls to <span className={"gradient-pink-text"}>Sagittarius</span>
          today. Embrace new experiences; they will lead to personal growth.
        </p>
      ),
    },
  },
};
