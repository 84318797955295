import React, { FC, ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./HorizontalCard.module.scss";

type HorizontalCardProps = {
  type: string;
  isSelected: boolean;
  icon: () => ReactNode;
  setType: (val: string) => void;
};
const HorizontalCard: FC<HorizontalCardProps> = ({
  type,
  icon,
  isSelected,
  setType,
}) => {

  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <button
      className={classNames(
        styles.card,
        { [styles.cardSelected]: isSelected },
        "pointer"
      )}
      style={{
        height: innerHeight > 730 ? "60px" : "50px",
      }}
      onClick={() => setType(type)}
    >
      <span className={styles.icon}>{icon()}</span>
      <p className={styles.text}>{type}</p>
    </button>
  );
};

export default HorizontalCard;
