import React, { forwardRef } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { Subscription } from "../PaymentPage";
import DefaultButton from "components/buttons/DefaultButton";
import StripeModal from "components/modals/StripeModal/StripeModal";

export const subscriptionsList: Subscription[] = [
  {
    id: "1c9a336b-9658-42d1-ae29-456241eccd01",
    price: "1",
    period: "1 Week trial",
    text: "then 2 weeks plan - $1.35/Day",
    perDay: "USD 1.7/DAY",
    oldPrice: "$18.99",
    priceId: "price_1P06lbItCWgNXKMKNOjcijjF", //live price
    // priceId: "price_1OvaOzItCWgNXKMKaTpwXLai", //test
  },
  {
    id: "62fca830-750c-4784-8482-7a3799d55d4a",
    price: "3",
    period: "10 days trial",
    text: "Then 2 weeks plan - $1.10/Day",
    perDay: "USD 1.3/DAY",
    oldPrice: "$19.99",
    label: "most popular",
    // priceId: "price_1P06mIItCWgNXKMKhf6OOAn9", //live price
    priceId: "price_1OvvXuItCWgNXKMKSuYfCRPz", //test,
  },
  {
    id: "b88348e1-dff8-4591-baeb-b998f53ed2e0",
    price: "5",
    period: "2 week trial",
    text: "Then one month plan - $0.90/Day",
    perDay: "USD 1.21/DAY",
    oldPrice: "$28.99",
    // priceId: "price_1P06nAItCWgNXKMKXGOrbzdP", //live price
    priceId: "price_1OvaSsItCWgNXKMKp4gyGR2m", //test
  },
  {
    id: "b88348e1-dff8-4591-baeb-b998f599d2e0",
    price: "10",
    period: "1 month trial",
    text: "Then one month plan - $0.90/Day",
    perDay: "USD 0.83/DAY",
    oldPrice: "$28.99",
    // priceId: "price_1P06nAItCWgNXKMKXGOrbzdP", //live price
    priceId: "price_1OvaSsItCWgNXKMKp4gyGR2m", //test
  },
  {
    id: "b88348e1-dff8-4591-baeb-b998f53e76e0",
    price: "25",
    period: "3 months",
    text: "Then one month plan - $0.90/Day",
    perDay: "USD 0.55/DAY",
    oldPrice: "$28.99",
    // priceId: "price_1P06nAItCWgNXKMKXGOrbzdP", //live price
    priceId: "price_1OvaSsItCWgNXKMKp4gyGR2m", //test
    label: "Save 50%",
  },
  {
    id: "b88348e1-dff8-4591-baeb-a998f53e76e0",
    price: "50",
    period: "6 months",
    text: "Then one month plan - $0.90/Day",
    perDay: "USD 0.55/DAY",
    oldPrice: "$28.99",
    // priceId: "price_1P06nAItCWgNXKMKXGOrbzdP", //live price
    priceId: "price_1OvaSsItCWgNXKMKp4gyGR2m", //test
    // label: "Save 50%",
  },
];

type SubscriptionListProps = {
  isModalOpen: boolean;
  setSubscriptionType: (val: string) => void;
  subscriptionType: string;
  toggleButtonModal: (val: boolean) => void;
  isStripeOpen: boolean;
  setIsOpenStripe: (val: boolean) => void;
  subscriptionData: any;
  setSubscriptionData: (val: any) => void;
};

const SubscriptionList = forwardRef<any, SubscriptionListProps>(
  (
    {
      isModalOpen,
      setSubscriptionType,
      subscriptionType,
      toggleButtonModal,
      isStripeOpen,
      setIsOpenStripe,
      subscriptionData,
      setSubscriptionData,
    },
    ref
  ) => {
    return (
      <>
        {subscriptionsList.map((subscription) => (
          <div style={{
            position: "relative",
          }}>
            <span>label</span>
            <SubscriptionCard
              itemData={subscription}
              key={subscription.id}
              label={subscription.label}
              isSelected={subscriptionType === subscription.id}
              onClick={() => {
                if (!isModalOpen) {
                  setSubscriptionType(subscription.id);
                  // setPriceId(subscription.priceId);
                  toggleButtonModal(false);
                  setSubscriptionData(subscription);
                  setIsOpenStripe(true);
                }
              }}
            />
          </div>
        ))}
        <div ref={ref} className={"mt32"}>
          <DefaultButton
            text="Start Trial"
            type="button"
            isDisabled={isModalOpen}
            onClick={() => {
              console.log("open stripe");
              toggleButtonModal(false);
              setTimeout(() => setIsOpenStripe(true), 100);
            }}
            customStyles="button-shadow"
          />
          {isStripeOpen && (
            <StripeModal
              isOpen={true}
              paymentType="payment"
              setIsOpenStripe={setIsOpenStripe}
              toggleButtonModal={toggleButtonModal}
              subscriptionData={subscriptionData}
            />
          )}
        </div>
      </>
    );
  }
);

export default SubscriptionList;

SubscriptionList.displayName = "SubscriptionList";
