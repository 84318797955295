import React from "react";
const Female = ({ isSelected }: { isSelected: boolean }) => (
  // <svg
  //   width="27"
  //   height="38"
  //   viewBox="0 0 27 38"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M6.85324 6.42038C3.02733 10.2463 3.02733 16.4496 6.85324 20.2758C10.6791 24.1017 16.8825 24.1017 20.7087 20.2758C24.5346 16.4496 24.5346 10.2466 20.7087 6.42038C16.8828 2.59419 10.6794 2.59447 6.85324 6.42038ZM5.78748 5.35462C1.37292 9.76917 1.37292 16.9269 5.78748 21.3415C7.80302 23.357 10.3903 24.4524 13.0272 24.6276V29.0136H8.13063C7.71441 29.0136 7.37696 29.351 7.37696 29.7673C7.37696 30.1834 7.71441 30.5208 8.13063 30.5208H13.0272V35.3103C13.0272 35.7265 13.3647 36.0639 13.7809 36.0639C14.1971 36.0639 14.5346 35.7265 14.5346 35.3103V30.5208H19.4349C19.8511 30.5208 20.1886 30.1834 20.1886 29.7673C20.1886 29.351 19.8511 29.0136 19.4349 29.0136H14.5346V24.6276C17.1715 24.4525 19.7587 23.3571 21.7743 21.3415C26.1889 16.9269 26.1889 9.76917 21.7743 5.35462C17.3598 0.939778 10.202 0.939778 5.78748 5.35462Z"
  //     fill={isSelected ? "#E1DEF8" : "#E1DEF8"}
  //   />
  // </svg>
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="48" 
  height="80" 
  viewBox="0 0 36 52"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.80025 8.58206C3.50284 13.8795 3.50284 22.4687 8.80025 27.7665C14.0977 33.0639 22.6869 33.0639 27.9847 27.7665C33.2821 22.4687 33.2821 13.8799 27.9847 8.58206C22.6873 3.28425 14.0981 3.28464 8.80025 8.58206ZM7.32458 7.10638C1.21212 13.2188 1.21212 23.1296 7.32458 29.242C10.1153 32.0328 13.6978 33.5495 17.3489 33.7921V39.865H10.5689C9.99263 39.865 9.5254 40.3322 9.5254 40.9085C9.5254 41.4846 9.99263 41.9519 10.5689 41.9519H17.3489V48.5834C17.3489 49.1597 17.8161 49.627 18.3924 49.627C18.9687 49.627 19.4359 49.1597 19.4359 48.5834V41.9519H26.221C26.7973 41.9519 27.2645 41.4846 27.2645 40.9085C27.2645 40.3322 26.7973 39.865 26.221 39.865H19.4359V33.7921C23.0871 33.5496 26.6693 32.0329 29.4602 29.242C35.5727 23.1296 35.5727 13.2188 29.4602 7.10638C23.3478 0.99353 13.437 0.99353 7.32458 7.10638Z"
    fill={isSelected ? "#E1DEF8" : "#E1DEF8"}
  />
</svg>


  
);

export default Female;