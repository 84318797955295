import React from "react";

const LookingForLoveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M20.7801 13.8432C20.7801 13.8432 18.5017 11.9232 16.8121 14.5343C15.1225 11.8975 12.8441 13.8432 12.8441 13.8432C11.2569 15.2768 12.8441 17.1968 12.8441 17.1968C13.4329 18.2464 16.4281 21.088 16.7865 21.4464L16.8121 21.472C17.0681 21.216 20.1657 18.2976 20.7801 17.1968C20.7801 17.1968 22.3672 15.2768 20.7801 13.8432L20.7801 13.8432Z"
      fill="url(#paint0_linear_7036_4382)"
    />
    <path
      d="M16.8128 5.98401C11.0016 5.98401 6.26562 10.72 6.26562 16.5568C6.26562 22.368 11.0016 27.104 16.8128 27.104C18.7072 27.104 20.576 26.592 22.24 25.5936L22.624 25.3632L27.4624 30.2016C27.7696 30.5344 28.2048 30.688 28.6656 30.688C29.1264 30.688 29.536 30.5088 29.8687 30.2016C30.1759 29.8944 30.3552 29.4591 30.3552 28.9984C30.3552 28.5376 30.176 28.128 29.8687 27.7953L25.1327 23.0849L25.5423 22.4704C26.7199 20.7296 27.3599 18.6816 27.3599 16.5568C27.3855 10.72 22.6495 5.98403 16.8127 5.98403L16.8128 5.98401ZM16.8128 24.2368C12.5632 24.2368 9.10722 20.7808 9.10722 16.5312C9.10722 12.2816 12.5632 8.82561 16.8128 8.82561C21.0624 8.82561 24.5184 12.2816 24.5184 16.5312C24.5184 20.8064 21.0624 24.2368 16.8128 24.2368Z"
      fill="url(#paint1_linear_7036_4382)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7036_4382"
        x1="11.5311"
        y1="23.968"
        x2="24.1226"
        y2="19.0481"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7036_4382"
        x1="4.69981"
        y1="38.0992"
        x2="38.2473"
        y2="26.7211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default LookingForLoveIcon;
