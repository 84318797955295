import React from "react";
const CoffeeCupIcon = () => (

  <svg
    width={50}
    height={51}
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_297_14966)">
      <mask
        id="mask0_297_14966"
        style={{maskType: "luminance"}}
        maskUnits="userSpaceOnUse"
        x={4}
        y={0}
        width={42}
        height={50}
      >
        <path
          d="M4.1875 0.0999756H45.7875V49.9666H4.1875V0.0999756Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_297_14966)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0841 30.0655V31.078C38.0841 32.8093 37.7706 34.4708 37.1987 36.0093C37.805 36.5114 38.5831 36.8135 39.4321 36.8135C41.3685 36.8135 42.9383 35.2426 42.9383 33.3051C42.9383 31.3676 41.3685 29.7968 39.4321 29.7968C38.9539 29.7968 38.4987 29.8926 38.0841 30.0655ZM11.1737 26.2478C11.1737 26.2478 10.4321 32.4926 12.3196 36.2801C14.2081 40.0666 16.8373 41.927 18.8602 42.7905C20.8831 43.6541 20.8831 43.6541 20.8831 43.6541C14.4071 38.9489 15.1498 33.3322 15.556 26.2478C15.556 26.2478 11.1071 26.2478 11.1737 26.2478ZM45.7758 47.5343C45.1008 49.0416 43.5852 50.0978 41.8352 50.0978H8.12373C6.37373 50.0978 4.85811 49.0416 4.18311 47.5343H45.7758ZM23.2529 0.098877C26.4696 2.077 26.6289 4.78638 26.0664 6.45096C25.38 8.477 23.3258 10.0062 23.0091 11.6687C22.7529 13.0187 24.3029 14.8989 25.006 16.0208H25.005C22.431 14.7583 20.33 12.5812 22.3508 9.46971C23.9029 7.08221 26.9435 5.20825 23.2529 0.098877ZM30.6019 4.01242C33.8185 5.9895 33.9789 8.69888 33.4144 10.3645C32.6873 12.5155 30.4144 14.1072 30.3206 15.8937C30.2612 17.0353 31.7477 18.9645 32.355 19.9333L32.3539 19.9343C29.7789 18.6708 27.6779 16.4937 29.6998 13.3833C31.2519 10.9947 34.2925 9.12075 30.6019 4.01242ZM15.9029 4.01242C19.1206 5.9895 19.28 8.69888 18.7164 10.3645C18.1258 12.1093 16.5185 13.4864 15.8831 14.8989C15.0644 16.7166 16.6904 18.3916 17.6571 19.9333L17.656 19.9343C15.081 18.6708 12.98 16.4937 15.0019 13.3833C16.5539 10.9947 19.5935 9.12075 15.9029 4.01242ZM9.63102 24.3999H38.0841V27.6614C38.5164 27.5572 38.9685 27.503 39.4321 27.503C42.6341 27.503 45.231 30.101 45.231 33.3051C45.231 36.5093 42.6341 39.1072 39.4321 39.1072C38.2373 39.1072 37.1279 38.7458 36.205 38.126C33.7456 42.4103 29.1237 45.3124 23.8581 45.3124C16.0331 45.3124 9.63102 38.9072 9.63102 31.078V24.3999Z"
          fill="url(#paint0_linear_297_14966)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_297_14966"
        x1="1.47958"
        y1="65.0975"
        x2="61.076"
        y2="47.8541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5784F8" />
        <stop offset={1} stopColor="#F5B6FF" />
      </linearGradient>
      <clipPath id="clip0_297_14966">
        <rect
          width={50}
          height={50}
          fill="white"
          transform="translate(0 0.0999756)"
        />
      </clipPath>
    </defs>
  </svg>


);
export default CoffeeCupIcon;
