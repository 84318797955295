import React from "react";
import StepsTemplate from "../StepsTemplate";
import DefaultButton from "components/buttons/DefaultButton";
import { useAppDispatch } from "store";
import { goNextStep } from "store/slices/registration";
import Palm from "../../../../assets/svg/Palm";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

import styles from "./InfoAboutPalmsStep.module.scss";

const InfoAboutPalmsStep = () => {
  const dispatch = useAppDispatch();

  const onClickGoNext = () => {
    ReactGA.event("InfoAboutPalmsStepChecked");
    ReactPixel.trackCustom("InfoAboutPalmsStepChecked");
    dispatch(goNextStep());
  };

  return (
    // <StepsTemplate>
      <div className={styles.container}>
        <h2 className={styles.title}>
          <span className="gradient-pink-text">The lines on your palms</span>{" "}
          offer significant insights into your
          <span className="gradient-pink-text"> personality</span> and
          <span className="gradient-pink-text"> fate</span>.
        </h2>

        <div className={styles.imageContainer}>
          <Palm />
        </div>
        <div className={styles.btnContainer}>
          <DefaultButton text={"Next"} type="button" onClick={onClickGoNext} />
        </div>
      </div>
    // </StepsTemplate>
  );
};

export default InfoAboutPalmsStep;
