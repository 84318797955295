import React from "react";

const Male = ({ isSelected }: { isSelected: boolean }) => (
  // <svg
  //   width="31"
  //   height="30"
  //   viewBox="0 0 31 30"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <g clipPath="url(#clip0_186_11499)">
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M29.3703 -0.00025354H22.073C21.6252 -0.00025354 21.2622 0.362746 21.2622 0.810597C21.2622 1.25835 21.6252 1.62135 22.073 1.62135H27.4128L20.6698 8.36435C18.5305 6.4914 15.7288 5.35635 12.6623 5.35635C5.94525 5.35635 0.5 10.8016 0.5 17.5184C0.5 24.2354 5.94525 29.6806 12.6623 29.6806C19.3793 29.6806 24.8243 24.2354 24.8243 17.5184C24.8243 14.4516 23.6893 11.6501 21.8163 9.51085L28.5593 2.76785V8.1076C28.5593 8.55545 28.9223 8.91845 29.3701 8.91845C29.818 8.91845 30.181 8.55545 30.181 8.1076V0.810347C30.181 0.362496 29.818 -0.00050354 29.3701 -0.00050354L29.3703 -0.00025354ZM23.2028 17.5185C23.2028 23.34 18.4835 28.059 12.6623 28.059C6.84075 28.059 2.12175 23.3398 2.12175 17.5185C2.12175 11.697 6.841 6.978 12.6623 6.978C18.4835 6.978 23.2028 11.6972 23.2028 17.5185Z"
  //       fill={isSelected ? "#E1DEF8" : "#E1DEF8"}
  //     />
  //   </g>
  //   <defs>
  //     <clipPath id="clip0_186_11499">
  //       <rect width="30" height="30" fill="white" transform="translate(0.5)" />
  //     </clipPath>
  //   </defs>
  // </svg>

  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="48" 
  height="80"
  viewBox="0 0 36 36"
  fill="none"
>
  <g clip-path="url(#clip0_271_18517)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.6443 -0.00020354H25.8876C25.3502 -0.00020354 24.9146 0.435396 24.9146 0.972816C24.9146 1.51012 25.3502 1.94572 25.8876 1.94572H32.2953L24.2037 10.0373C21.6366 7.78978 18.2745 6.42772 14.5947 6.42772C6.5343 6.42772 0 12.962 0 21.0221C0 29.0825 6.5343 35.6168 14.5947 35.6168C22.6551 35.6168 29.1891 29.0825 29.1891 21.0221C29.1891 17.342 27.8272 13.9802 25.5795 11.4131L33.6711 3.32152V9.72922C33.6711 10.2666 34.1067 10.7022 34.6441 10.7022C35.1815 10.7022 35.6171 10.2666 35.6171 9.72922V0.972516C35.6171 0.435096 35.1815 -0.00050354 34.6441 -0.00050354L34.6443 -0.00020354ZM27.2433 21.0223C27.2433 28.0081 21.5802 33.6709 14.5947 33.6709C7.6089 33.6709 1.9461 28.0078 1.9461 21.0223C1.9461 14.0365 7.6092 8.3737 14.5947 8.3737C21.5802 8.3737 27.2433 14.0368 27.2433 21.0223Z"
    fill={isSelected ? "#E1DEF8" : "#E1DEF8"}
/>
  </g>
  <defs>
    <clipPath id="clip0_271_18517">
      <rect width="36" height="36" fill="white" />
    </clipPath>
  </defs>
</svg>
);

export default Male;