import React, { useEffect } from "react";
import styles from "./BestMatchesReport.module.scss";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { mockedData } from "./mockedData";
import InfoCard from "./components/InfoCard/InfoCard";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const BestMatchesReport = () => {
  const { sign } = useLocation().state;

  useEffect(() => {
    ReactPixel.track("Best Matches Report Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/best-matches-report",
      title: "Best Matches Report Page",
    });
  }, []);

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <div className={styles.content}>
          <h1 className="t-align-l">
            Best <span className={"gradient-pink-text"}>Matches</span>
          </h1>
          <div className="mt24 flex flex-column i-align-c">
            <h2>
              for<span className="gradient-pink-text"> {sign.title}</span>
            </h2>
            <div
              className={classNames(
                styles.zodiacContainer,
                "flex i-align-c mt8 mb32",
              )}
            >
              <img src={sign.iconUrl} alt={""} />
            </div>
            <div className="flex flex-column gap12 padding-b40">
              {mockedData.map((item, index) => (
                <InfoCard
                  zodiacs={item.icons}
                  customContainerStyles={"padding-v18 padding-h18"}
                  key={index}
                  title={item.title}
                  btnColor={item.btnColor}
                  btnTitle={item.btnTitle}
                  cardText={item.cardText}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BestMatchesReport;
