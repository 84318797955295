import React, { ChangeEventHandler, FC, useRef, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";
import StripeInput from "./StripeInput/StripeInput";
import DefaultInput from "../../fields/DefaultInput";
import Checkmark from "assets/svg/checkmark.svg";
import DefaultButton from "../../buttons/DefaultButton";
import ReactGA from "react-ga4";
import http from "helpers/http";
import { useAppDispatch, useAppSelector } from "store";
import { updateUserAccountExpiration } from "store/slices/user";
import { useNavigate } from "react-router-dom";
import { startLoading, stopLoading } from "store/slices/loader";
import * as stripeJs from "@stripe/stripe-js";
import ReactPixel from "react-facebook-pixel";

const CARD_OPTIONS: StripeCardNumberElementOptions = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#827677",
      fontWeight: 500,
      fontFamily: "Lora",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {color: "#827677"},
      "::placeholder": {color: "#827677", fontWeight: 400},
    },
    invalid: {
      iconColor: "#FE6363",
      color: "#FE6363",
    },
  },
};

type PaymentFormProps = {
  paymentType?: "subscription" | "report";
  setCardName: ChangeEventHandler<HTMLInputElement>;
  cardName: string;
  toggleModal: (value: boolean) => void;
  planData: {
    id: string;
    price?: string;
    period?: string;
    oldPrice?: string;
  };
};
const PaymentForm: FC<PaymentFormProps> = ({
  setCardName,
  cardName,
  planData,
  toggleModal,
  paymentType,
}) => {
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const elements = useElements();
  const navigate = useNavigate();
  const [isSaveCardChecked, toggleCheckbox] = useState(true);
  const { price = 0 } = planData;
  const { email, genderType } = useAppSelector((state) => state.user.userData);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const handleSubmit = async () => {
    ReactGA.event("clickedPayButton");
    ReactPixel.trackCustom("clickedPayButton");
    dispatch(startLoading());
    ReactGA.event("clickPayment");
    if (elements) {
      const cardElement = elements.getElement(CardNumberElement);
      if (stripe && elements) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement as StripeCardNumberElement,
          billing_details: {
            email,
          },
        });

        if (!error) {
          try {
            const { id } = paymentMethod;
            const requestUrl =
              paymentType === "report"
                ? "/payment/create-payment-intent"
                : "/payment/create-payment-subscription";

            const res = await http.post(requestUrl, {
              packageType: planData.id,
              paymentMethod: id,
              email,
              isSaveCard: isSaveCardChecked,
            });
            if (res.actionRequired) {
              dispatch(stopLoading());
              const { paymentIntent, error } = await stripe.confirmCardPayment(
                res.clientSecret,
              );
              if (error) return console.log(error);
              if (paymentIntent.status === "succeeded") {
                dispatch(startLoading());
                const res2 = await http.get(`/payment/payment-confirm`, {
                  params: {
                    id: res.id,
                    email,
                    packageType: planData.id,
                  },
                });
                dispatch(updateUserAccountExpiration(res2.newExpirationDate));
              }
            } else {
              dispatch(updateUserAccountExpiration(res.newExpirationDate));
            }
            ReactGA.event("paymentSucceeded");
            ReactPixel.trackCustom("paymentSucceeded");
            toggleModal(false);
            setTimeout(() => {
              if (!genderType) {
                navigate("/load");
              } else {
                navigate("/home");
              }
            }, 200);
          } catch (error) {
            ReactGA.event("paymentError");
            ReactPixel.trackCustom("paymentError");
            dispatch(stopLoading());
            console.log("Error", error);
          }
        } else {
          ReactGA.event("paymentError");
          ReactPixel.trackCustom("paymentError");
          dispatch(stopLoading());
          console.log(error.message);
        }
      }
    }
    dispatch(stopLoading());
  };

  const handleCardNumberChange = (
    event: stripeJs.StripeCardNumberElementChangeEvent,
  ) => {
    if (event.complete) {
      ReactGA.event("paymentCardNumberEntered");
      ReactPixel.trackCustom("paymentCardNumberEntered");
      if (elements) {
        const el = elements.getElement(CardExpiryElement);
        if (el) {
          el.focus();
        }
      }
    }
  };

  const handleExpiryChange = (
    event: stripeJs.StripeCardExpiryElementChangeEvent,
  ) => {
    if (event.complete) {
      ReactGA.event("paymentCardExpiryEntered");
      ReactPixel.trackCustom("paymentCardExpiryEntered");
      if (elements) {
        const el = elements.getElement(CardCvcElement);
        if (el) {
          el.focus();
        }
      }
    }
  };

  const handleCvcChange = (event: stripeJs.StripeCardCvcElementChangeEvent) => {
    if (event.complete) {
      ReactGA.event("paymentCardCvcEntered");
      ReactPixel.trackCustom("paymentCardCvcEntered");

      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }
  };

  return (
    <>
      <StripeInput>
        <CardNumberElement
          options={CARD_OPTIONS}
          onChange={handleCardNumberChange}
        />
      </StripeInput>
      <div className="flex gap12">
        <div className="full-width">
          <p className="text-uppercase font-s10 text-color-silver fMontserrat mt16 mb6">
            expiry date
          </p>
          <StripeInput>
            <CardExpiryElement
              options={CARD_OPTIONS}
              onChange={handleExpiryChange}
            />
          </StripeInput>
        </div>
        <div className="full-width">
          <p className="text-uppercase font-s10 text-color-silver fMontserrat mt16 mb6">
            cvv/cvc
          </p>
          <StripeInput>
            <CardCvcElement options={CARD_OPTIONS} onChange={handleCvcChange} />
          </StripeInput>
        </div>
      </div>
      <div>
        <p className="text-uppercase font-s10 text-color-silver fMontserrat mt16 mb6">
          name on card
        </p>
        <DefaultInput
          ref={nameInputRef}
          name="cardNumber"
          handleChange={setCardName}
          inputVal={cardName}
          placeholder="Michael Smith"
        />
      </div>
      <div
        className="fRoboto font-s10 font-w400 text-color-silver t-align-l flex gap6 justify-content-start i-align-s mt12 mb24"
        onClick={() => toggleCheckbox(!isSaveCardChecked)}
      >
        <div
          className=""
          style={{
            width: 12,
            height: 12,
            border: "1px solid #FFD700",
            boxShadow: "0 0 1px 0 #FFD700",
            borderRadius: 2,
            padding: "0 1px",
          }}
        >
          {isSaveCardChecked && (
            <img src={Checkmark} alt="" width={8} height={8} />
          )}
        </div>
        <p>Save card for future use</p>
      </div>
      <DefaultButton text={`Pay $${price}`} onClick={handleSubmit} />
    </>
  );
};

export default PaymentForm;
