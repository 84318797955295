import React, { useEffect, useState } from "react";
import styles from "./TarotReadingPage.module.scss";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import DefaultButton from "components/buttons/DefaultButton";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { fetchTarotReading, TarotCardData } from "store/slices/tarotReading";
import RandomFlippedTarotCard from "../../components/RandomFlippedTarotCard/RandomFlippedTarotCard";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

export type CardType = {
  card: string;
  isCardFlipped: boolean;
};
const TarotReadingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tarotReadingResult } = useAppSelector((state) => state.tarotReading);

  const [selectedCardsCount, setSelectedCardsCount] = useState(0);
  const [tarotResult, setTarotResult] = useState<TarotCardData[]>([]);
  const [cardsArray, setCardsArray] = useState([
    { id: 1, isCardFlipped: false, cardData: null },
    { id: 2, isCardFlipped: false, cardData: null },
    { id: 3, isCardFlipped: false, cardData: null },
    { id: 4, isCardFlipped: false, cardData: null },
    { id: 5, isCardFlipped: false, cardData: null },
    { id: 6, isCardFlipped: false, cardData: null },
    { id: 7, isCardFlipped: false, cardData: null },
    { id: 8, isCardFlipped: false, cardData: null },
  ]);

  useEffect(() => {
    ReactPixel.track("Tarot Reading Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/tarot-reading",
      title: "Tarot Reading Page",
    });
    const getTarotResult = async () => {
      await dispatch(fetchTarotReading()).unwrap();
      setTarotResult(tarotReadingResult);
    };
    getTarotResult();
  }, []);

  const toggleCard = (id: number) => {
    if (selectedCardsCount !== 3) {
      const newCardsState = JSON.parse(JSON.stringify(cardsArray));
      const toggledCard = newCardsState.find(
        (item: { id: number }) => item.id === id,
      );

      if (toggledCard) {
        const newTarotResult = JSON.parse(JSON.stringify(tarotResult));
        toggledCard.isCardFlipped = true;
        toggledCard.cardData = newTarotResult[0];

        newTarotResult.splice(0, 1);
        setTarotResult(newTarotResult);
        setCardsArray(newCardsState);
        setSelectedCardsCount((prev) => prev + 1);
      }
    }
  };

  const onClickGetResult = async () => {
    navigate("/tarot-reading-result");
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <div className={styles.content}>
            <h1>
              <span className="gradient-pink-text">Tarot</span> reading
            </h1>
            <div className={classNames(styles.cardsContainer, "mt24")}>
              {cardsArray
                .slice(0, 4)
                .map(
                  (card: {
                    cardData: { card: string } | null;
                    id: number;
                    isCardFlipped: boolean;
                  }) => (
                    <RandomFlippedTarotCard
                      key={card.id}
                      cardUrl={card.cardData?.card}
                      cardId={card.id}
                      isCardFlipped={card.isCardFlipped}
                      customSceneStyles={{ height: 126.653 }}
                      customCardFaceStyles={{ height: 126.653 }}
                      onClick={toggleCard}
                    />
                  ),
                )}
            </div>
            <div className={classNames(styles.cardsContainer, "mt24")}>
              {cardsArray
                .slice(4, 8)
                .map(
                  (card: {
                    cardData: { card: string } | null;
                    id: number;
                    isCardFlipped: boolean;
                  }) => (
                    <RandomFlippedTarotCard
                      key={card.id}
                      cardUrl={card.cardData?.card}
                      cardId={card.id}
                      isCardFlipped={card.isCardFlipped}
                      customSceneStyles={{ height: 126.653 }}
                      customCardFaceStyles={{ height: 126.653 }}
                      onClick={toggleCard}
                    />
                  ),
                )}
            </div>
            <div>
              <h2 style={{ textAlign: "center" }} className={"mt32"}>
                Choose <span className={"gradient-pink-text"}>three cards</span>
              </h2>
              <h2 style={{textAlign: "center"}} className={"mt12"}>
                {selectedCardsCount}/3
              </h2>
            </div>
            <div className={"text bottom-container-absolute"}>
              <DefaultButton
                text={"Get reading"}
                isDisabled={selectedCardsCount !== 3}
                onClick={onClickGetResult}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TarotReadingPage;
