import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import ConfettiIcon from "assets/svg/noun-confetti.svg";
import { purchasesData } from "constants/purchases";
import PurchaseCard from "./components/PurchaseCard/PurchaseCard";
import DefaultButton from "components/buttons/DefaultButton";
import { useAppSelector } from "store";
// import ScrollDown from "./components/animation/ScrollDown";
import PaymentDetailsModal from "components/modals/PaymentDetailsModal/PaymentDetailsModal";
import Loader from "components/Loader/Loader";
import { addClassToModalDiv } from "utils/defaults";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import ScrollDown from "assets/svg/scroll-down.svg"
import styles from "./Purchase.module.scss"

export type Report = {
  type: string;
  id: string;
  price: string;
  period: string;
  perDay: string;
  oldPrice: string;
  oldDayPrice: string;
  label?: string;
};

const reportsList = [
  {
    id: "694218f3-0eea-4a7f-aa5a-d4e0205a0af6",
    type: "oneReport",
    price: "19.99",
    selectedReportsCount: 1,
  },
  {
    type: "twoReports",
    price: "27.99",
    id: "8c07e7d7-174e-463c-a5f1-700565be3c9d",
    selectedReportsCount: 2,
  },
  {
    type: "threeReports",
    price: "35.99",
    id: "16ee7f8b-cc81-46f5-81ee-d5c7d61a70d8",
    selectedReportsCount: 3,
  },
];

const Purchase = () => {
  const [selectedPurchases, setSelectedPurchases] = useState<string[]>([]);
  const { romantic, friendship, personalConnection } = useAppSelector(
    (state) => state.purchases.purchases,
  );

  const [isPaymentDetailsModalOpen, togglePaymentDetailsModal] =
    useState(false);

  useEffect(() => {
    if (isPaymentDetailsModalOpen) {
      addClassToModalDiv();
    }
  }, [isPaymentDetailsModalOpen]);

  useEffect(() => {
    ReactPixel.track("Purchases Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/purchases",
      title: "Purchases Page",
    });
    window.scrollTo(0, 0);
  }, []);
  const togglePurchase = (type: string) => {
    setSelectedPurchases((prevPurchases) => {
      const index = prevPurchases.indexOf(type);
      if (index !== -1) {
        return prevPurchases
          .slice(0, index)
          .concat(prevPurchases.slice(index + 1));
      } else {
        return [...prevPurchases, type];
      }
    });
  };

  const btnText =
    selectedPurchases.length === 1
      ? "Unlock now for $19.99"
      : selectedPurchases.length === 2
        ? "Unlock now for $27.99"
        : selectedPurchases.length === 3
          ? "Unlock now for $35.99"
          : "Unlock now";

  const onClickUnlock = () => {
    togglePaymentDetailsModal(true);
    // dispatch(setPurchases(selectedPurchases));
    // navigate("/full-compatibility-report");
    // dispatch(openSuccessfulPurchaseModal());
  };

  return (
    <Layout>
      <div className="app-container">
        <div className="padding-content-h mb24">
          <HeaderWithBack />
          <h1 className="t-align-l">
            Unlock <span className="gradient-pink-text">Personalized</span>{" "}
            Compatibility Insights
          </h1>
        </div>
        <div className="bg-lavender padding-v24 font-s18 font-w400 flex flex-column i-align-c gap8">
          <p className="brownish-gray-text fLora">
            Each report for <span className="gradient-pink-text">$19.99</span>
          </p>
          <img src={ConfettiIcon} alt="" width={60} height={60} />
          <p className="fMontserrat font-s10 font-w600 brownish-gray-text fGolos text-uppercase">
            enjoy a special offer:
          </p>
          <p className="brownish-gray-text fLora">
            Two reports for <span className="gradient-pink-text">$27.99</span>
          </p>
          <p className="brownish-gray-text fLora">
            Three reports for only <span className="gradient-pink-text">$35.99!</span>
          </p>
        </div>
        <div className="position-relative" style={{margin: "65px 0 45px 0"}}>
          {/* <ScrollDown /> */}
          <img src={ScrollDown} alt="" className={styles.scrollDownAnimation} />
        </div>
        <div className="padding-content-h mb24">
          <h2 className="t-align-l">
            <span className="gradient-pink-text">Select</span> report(s)
          </h2>
          <div className="flex gap12 flex-column mt24">
            {purchasesData.map((item, index) => (
              <PurchaseCard
                key={index}
                iconUrl={item.iconUrl}
                text={item.text}
                type={item.type}
                isSelected={!!selectedPurchases.find((el) => el === item.type)}
                onClickPurchase={togglePurchase}
                isPurchased={
                  item.type === "romantic"
                    ? romantic
                    : item.type === "friendship"
                      ? friendship
                      : personalConnection
                }
              />
            ))}
          </div>
          <div className="mt32 padding-b40">
            <DefaultButton
              style={{ zIndex: 5 }}
              text={btnText}
              isDisabled={!selectedPurchases.length}
              onClick={onClickUnlock}
            />
          </div>
        </div>
        {!!selectedPurchases.length && (
          <PaymentDetailsModal
            paymentType="report"
            planData={
              reportsList.filter(
                (item) =>
                  item.selectedReportsCount === selectedPurchases.length,
              )[0]
            }
            isOpen={isPaymentDetailsModalOpen}
            toggleModal={togglePaymentDetailsModal}
          />
        )}
      </div>
      <Loader />
    </Layout>
  );
};

export default Purchase;
