import React from "react";

const CloseBtnIcon = ({ fill = "#F86557" }: { fill?: string }) => (
  // <svg
  //   width="26"
  //   height="21"
  //   viewBox="0 0 26 21"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <line x1="5.57516" y1="16.7886" x2="19.7173" y2="2.64646" stroke={fill} />
  //   <line x1="5.35355" y1="2.64645" x2="19.4957" y2="16.7886" stroke={fill} />
  // </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="21"
    viewBox="0 0 26 21"
    fill="none"
  >
    <path
      d="M5.92969 17.1422L20.0718 3.00002"
      stroke="url(#paint0_linear_271_5016)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M6 3L20.1421 17.1421"
      stroke="url(#paint1_linear_271_5016)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_271_5016"
        x1="8.93489"
        y1="16.1168"
        x2="6.93439"
        y2="13.8846"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_271_5016"
        x1="7.0253"
        y1="6.0052"
        x2="9.25756"
        y2="4.0047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
    </defs>
  </svg>
);

export default CloseBtnIcon;
