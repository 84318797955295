import React from "react";

const WrongIcon = () => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 0C7.2 0 0.5 6.7 0.5 15C0.5 23.3 7.2 30 15.5 30C23.8 30 30.5 23.3 30.5 15C30.5 6.7 23.8 0 15.5 0ZM22.05 19.4C22.6499 19.9999 22.6499 20.95 22.05 21.5501C21.75 21.8501 21.35 22.0001 21 22.0001C20.6 22.0001 20.2001 21.8501 19.9501 21.5501L15.5001 17.1501L11.1001 21.5501C10.8001 21.8501 10.4 22.0001 10.0501 22.0001C9.65006 22.0001 9.25017 21.8501 9.00017 21.5501C8.40025 20.9502 8.40025 20.0001 9.00017 19.4L13.4002 15L9.00017 10.6C8.40025 10.0001 8.40025 9.05004 9.00017 8.44992C9.60008 7.85 10.5501 7.85 11.1502 8.44992L15.5503 12.8499L19.9503 8.44992C20.5502 7.85 21.5002 7.85 22.1003 8.44992C22.7003 9.04983 22.7003 9.99988 22.1003 10.6L17.6503 15L22.05 19.4Z" fill="url(#paint0_linear_2076_30255)" />
    <defs>
      <linearGradient id="paint0_linear_2076_30255" x1="4.775" y1="42" x2="49.6198" y2="1.04673" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
    </defs>
  </svg>

);

export default WrongIcon;
