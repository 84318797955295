import React, { useState } from "react";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { dates } from "utils/defaults";
import "./styles.scss";

const TimePicker = () => {
  const [hour, setHour] = useState("");
  const [minutes, setMinutes] = useState("");
  const [ampm, setAmpm] = useState("");

  console.log("hour", hour, minutes, ampm);
  const updateTransformDay = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        setHour(panel.element.textContent);
      }
    });
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        if (["am", "pm"].some((el) => el === panel.element.textContent)) {
          setAmpm(panel.element.textContent);
        } else {
          setMinutes(panel.element.textContent);
        }
      }
    });
  };
  return (
    <div className="date-demo-wrapper has-text-white position-relative flex">
      <Flicking
        className="date-flick"
        horizontal={false}
        onReady={updateTransformDay}
        onMove={updateTransformDay}
      >
        {dates(1, 12)}
        <ViewportSlot>
          <div className="shadow-top"></div>
          <div className="shadow-bottom"></div>
        </ViewportSlot>
      </Flicking>
      <Flicking
        className="date-flicking"
        horizontal={false}
        onReady={updateTransform}
        onMove={updateTransform}
      >
        {dates(1, 60)}
        <ViewportSlot>
          <div className="shadow-top"></div>
          <div className="shadow-bottom"></div>
        </ViewportSlot>
      </Flicking>
      <Flicking
        className="date-flicking"
        horizontal={false}
        onReady={updateTransform}
        onMove={updateTransform}
      >
        <div className="date-panel">am</div>
        <div className="date-panel">pm</div>
        <ViewportSlot>
          <div className="shadow-top"></div>
          <div className="shadow-bottom"></div>
        </ViewportSlot>
      </Flicking>
      <div className="date-panel-border2" />
    </div>
  );
};

export default TimePicker;