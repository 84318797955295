import React from "react";

const OpenedCookieWithStars = () => (
  <svg width="66" height="90" viewBox="0 0 66 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.1081 31.9453C20.7699 34.017 16.5638 38.527 15.0371 44.3152C17.3252 39.7977 21.2908 36.2696 26.1081 34.5564V31.9453Z" fill="url(#paint0_linear_310_12868)" />
    <path d="M39.6035 32.0744V34.6654C44.2691 36.4127 48.1032 39.8746 50.345 44.2775C48.8407 38.6228 44.7767 34.1952 39.6035 32.0744Z" fill="url(#paint1_linear_310_12868)" />
    <path d="M30.2424 21.8873C28.8003 21.8873 27.6309 23.0564 27.6309 24.4988V34.1013C29.2512 33.6711 30.9438 33.4183 32.6973 33.418H32.7007C34.5667 33.4183 36.3667 33.6979 38.0799 34.1831L38.0902 24.4855C38.0917 23.279 38.8839 22.3615 40.6879 21.8878L30.2424 21.8873Z" fill="#827677" />
    <line x1="29.4844" y1="23.8908" x2="36.5122" y2="23.8908" stroke="#EDECF7" />
    <line x1="29.4844" y1="25.1686" x2="36.5122" y2="25.1686" stroke="#EDECF7" />
    <line x1="29.4844" y1="26.4464" x2="36.5122" y2="26.4464" stroke="#EDECF7" />
    <line x1="29.4844" y1="27.7241" x2="36.5122" y2="27.7241" stroke="#EDECF7" />
    <line x1="29.4844" y1="29.0019" x2="36.5122" y2="29.0019" stroke="#EDECF7" />
    <path d="M22.3648 71.9103C26.8127 68.3169 30.3244 61.9028 32.0331 54.2072C32.1022 53.9055 32.366 53.692 32.6801 53.692C32.9942 53.692 33.258 53.9055 33.3271 54.2072C35.0359 61.8903 38.5415 68.3106 42.9954 71.9103C48.5426 68.1787 51.847 61.8337 51.847 54.8542C51.847 43.6908 43.253 34.613 32.6864 34.613C22.1197 34.613 13.5137 43.6908 13.5137 54.8542C13.5137 61.84 16.8118 68.1787 22.3653 71.9103H22.3648Z" fill="url(#paint2_linear_310_12868)" />
    <path d="M16.4845 16.7987L17.1578 15.9422L16.3013 16.6155L16 14.1L15.6987 16.6155L14.8422 15.9422L15.5155 16.7987L13 17.1L15.5155 17.4012L14.8422 18.2578L15.6987 17.5845L16 20.1L16.3013 17.5845L17.1578 18.2578L16.4845 17.4012L19 17.1L16.4845 16.7987Z" fill="#827677" />
    <path d="M8.06526 37.2485L8.85079 36.2492L7.85148 37.0347L7.5 34.1L7.14852 37.0347L6.14921 36.2492L6.93474 37.2485L4 37.6L6.93474 37.9515L6.14921 38.9508L7.14852 38.1652L7.5 41.1L7.85148 38.1652L8.85079 38.9508L8.06526 37.9515L11 37.6L8.06526 37.2485Z" fill="#827677" />
    <path d="M40.646 6.69829L41.5438 5.55622L40.4017 6.45396L40 3.09998L39.5983 6.45396L38.4562 5.55622L39.354 6.69829L36 7.09998L39.354 7.50167L38.4562 8.64373L39.5983 7.74599L40 11.1L40.4017 7.74599L41.5438 8.64373L40.646 7.50167L44 7.09998L40.646 6.69829Z" fill="#827677" />
    <path d="M60.0653 24.2485L60.8508 23.2492L59.8515 24.0347L59.5 21.1L59.1485 24.0347L58.1492 23.2492L58.9347 24.2485L56 24.6L58.9347 24.9515L58.1492 25.9508L59.1485 25.1652L59.5 28.1L59.8515 25.1652L60.8508 25.9508L60.0653 24.9515L63 24.6L60.0653 24.2485Z" fill="#827677" />
    <defs>
      <linearGradient id="paint0_linear_310_12868" x1="14.3175" y1="48.0262" x2="29.9899" y2="43.1475" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_310_12868" x1="38.9053" y1="47.9385" x2="54.1554" y2="43.2697" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_310_12868" x1="11.022" y1="83.0995" x2="63.8018" y2="64.2321" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
    </defs>
  </svg>

);

export default OpenedCookieWithStars;
