import { AutoPlay } from "@egjs/flicking-plugins";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Add from "assets/svg/add.svg";
import AmericanExpress from "assets/svg/american-express.svg";
import Banner from "assets/svg/banner.svg";
import Checkmark from "assets/svg/checkmark.svg";
import CloseBtnIcon from "assets/svg/close-btn";
import cloudsImage from "assets/svg/clouds-img.svg";
import FaceBookIcon from "assets/svg/facebook-icon";
import GuranteeFaded from "assets/svg/guarantee-faded.svg";
import InstagramIcon from "assets/svg/instagram-icon";
import LogoIcon from "assets/svg/logoIcon.svg";
import MasterCard from "assets/svg/master-card.svg";
import PayPal from "assets/svg/paypal.svg";
import Quotes from "assets/svg/quotes.svg";
import safetyIcon from "assets/svg/safe-payment.svg";
import ServicesCarouselPic from "assets/svg/services-carousel-pic.svg";
import Visa from "assets/svg/visa.svg";
import XAppIcon from "assets/svg/x-app-icon";
import classNames from "classnames";
import DefaultButton from "components/buttons/DefaultButton";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import Layout from "components/Layout/Layout";
import Loader from "components/Loader/Loader";
import StripeModal from "components/modals/StripeModal/StripeModal";
import ReadMoreComponent from "components/ReadMoreComponent/ReadMoreComponent";
import Timer from "components/Timer/Timer";
import { getToken } from "helpers/localStorageHelpers";
import { getPaymentLocation } from "helpers/sessionStorageHelpers";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { stopLoading } from "store/slices/loader";
import { goToInfoAboutPalm } from "store/slices/registration";
import {
  addClassToModalDiv,
  addClassToSpecificHiddenModalDiv,
} from "utils/defaults";
import EmailModal from "views/PaymentPage/components/EmailModal/EmailModal";
import { subscriptionsList } from "views/PaymentPage/components/SubscriptionList";
import varified from "../../assets/svg/varified.svg";
import styles from "./PaymentNewPage.module.scss";
const { REACT_APP_STRIPE_SK } = process.env;
LogRocket.init("nwxyyv/astroday");

const stripePromise = loadStripe(`${REACT_APP_STRIPE_SK}`);

type Pricing = {
  price: string;
  duration: string;
  priceByDay: string;
  id: number;
  class: string;
  extraTag: string;
};

const PricingList: Pricing[] = [
  {
    price: "10",
    duration: "1 week trial",
    priceByDay: "USD 1.7/day",
    id: 0,
    class: "",
    extraTag: "",
  },
  {
    price: "13",
    duration: "10 days trial",
    priceByDay: "USD 1.3/day",
    id: 1,
    class: "mostPopular",
    extraTag: "Most Popular",
  },
  {
    price: "17",
    duration: "2 week trial",
    priceByDay: "USD 1.21/day",
    id: 2,
    class: "",
    extraTag: "",
  },
  {
    price: "25",
    duration: "1 Month trial",
    priceByDay: "USD 0.83/day",
    id: 3,
    class: "",
    extraTag: "",
  },
  {
    price: "50",
    duration: "3 Monthtrial",
    priceByDay: "USD 0.55/day",
    id: 4,
    class: "save50",
    extraTag: "Save 50%",
  },
];

const PaymentNewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentLocation = getPaymentLocation();
  const dispatch = useAppDispatch();

  
  const [subscriptionType, setSubscriptionType] = useState(
    "62fca830-750c-4784-8482-7a3799d55d4a"
  );

  const { userData } = useAppSelector((state) => state.user);
  const [readMoreHeight, setReadMoreHeight] = useState<number | string>(58);
  const { zodiacsArray } = useAppSelector((state) => state.compatibility);
  const token = getToken();
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      if (token) {
        navigate("/login");
      } else {
        dispatch(goToInfoAboutPalm());
        navigate("/registration");
      }
    });
    return window.removeEventListener("popstate", () => {
      console.log("remove");
    });
  }, [location]);

  const plugins = [
    new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false }),
  ];

  const [isModalOpen, toggleModal] = useState(false);
  console.log("isModalOpen: ", isModalOpen);
  const [isButtonModalOpen, toggleButtonModal] = useState(false);
  const [isStripeOpen, setIsOpenStripe] = useState(false);
  const [isOpenCustomPlan, setIsOpenCustomPlan] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(
    subscriptionsList[1]
  );

  const [isModalOpenFirstTime, toggleModalFirstTime] = useState(true);

  const [trialBtnRef, btnInView] = useInView({
    threshold: 0,
  });

  const scrollableContainer = document.getElementById("payment-container");

  useEffect(() => {
    const container = document.getElementById("main-container");
    if (isStripeOpen) {
      if (container) container.style.overflow = "hidden";
    } else if (!isStripeOpen) {
      if (container) container.style.overflow = "scroll";
    }
  }, [isStripeOpen]);

  useEffect(() => {
    const container = document.getElementById("main-container");
    if (!paymentLocation) {
      navigate("/login");
    } else if (
      isModalOpenFirstTime &&
      typeof userData.expirationDate === "undefined"
    ) {
      if (container) container.style.overflow = "hidden";
      toggleModal(true);
      toggleModalFirstTime(false);
    }
    addClassToModalDiv();
  }, [isModalOpenFirstTime, isModalOpen]);

  useEffect(() => {
    if (
      paymentLocation === "registration" ||
      paymentLocation === "analysing" ||
      typeof location?.state?.from === "undefined"
    ) {
      addClassToSpecificHiddenModalDiv(0);
      // addClassToSpecificHiddenModalDiv(2);
    } else if (paymentLocation === "login") {
      addClassToSpecificHiddenModalDiv(0);
    } else {
      addClassToSpecificHiddenModalDiv(1);
      // addClassToSpecificHiddenModalDiv(2);
    }
  }, [btnInView]);

  useEffect(() => {
    dispatch(stopLoading());
    if (paymentLocation === "analysing") {
      toggleButtonModal(true);
    }
  }, [paymentLocation]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payment",
      title: "Payment Page",
    });
    ReactPixel.track("Payment Page");
  }, []);

  const buttonModalHeader = () => {
    return (
      <div className="flex i-align-c justify-content-space-between ">
        {/* <Timer time={600} /> */}
        <DefaultButton
          type="button"
          text="Get Personal Prediction"
          customStyles="font-s16  btn-prediction"
          onClick={() => {
            scrollableContainer &&
              scrollableContainer.scrollIntoView({
                behavior: "smooth",
              });
          }}
        />
      </div>
    );
  };

  const updateTransform = (e: any) => {
    console.log("🚀 ~ updateTransform ~ e:", e);
  };

  console.log("🚀 ~ updateTransform ~ e:", subscriptionsList);

  const CustomModalHeader = () => {
    return (
      <div className="flex i-align-c justify-content-space-between " >
        <p className={styles.custom_modal_header}>
          Please select the trial period length
        </p>
        <span
          onClick={() => setIsOpenCustomPlan((prev) => !prev)}
          className="pointer"
        >
          <CloseBtnIcon />
        </span>
      </div>
    );
  };

  const CustomModalContent = () => {
    return (
      <div>
        <div
          className={styles.date_demo_wrapper}
        >
          <Flicking
            className={styles.date_flicking}
            horizontal={false}
            onReady={updateTransform}
            onMove={updateTransform}
            defaultIndex={4}
          >
            <div className={styles.date_panel}>1</div>
            <div className={styles.date_panel}>2</div>
            <div className={styles.date_panel}>3</div>
            <div className={styles.date_panel}>4</div>
            <div className={styles.date_panel}>5</div>
            <div className={styles.date_panel}>6</div>
            <div className={styles.date_panel}>7</div>
            <div className={styles.date_panel}>8</div>
            <div className={styles.date_panel}>9</div>
            <div className={styles.date_panel}>10</div>
            <div className={styles.date_panel}>11</div>
            <div className={styles.date_panel}>12</div>
            <div className={styles.date_panel}>13</div>
            <div className={styles.date_panel}>14</div>
            <div className={styles.date_panel}>15</div>
            <div className={styles.date_panel}>16</div>
            <div className={styles.date_panel}>17</div>
            <div className={styles.date_panel}>18</div>
            <div className={styles.date_panel}>19</div>
            <div className={styles.date_panel}>20</div>
            <div className={styles.date_panel}>21</div>
            <div className={styles.date_panel}>22</div>
            <div className={styles.date_panel}>23</div>
            <div className={styles.date_panel}>24</div>
            <div className={styles.date_panel}>25</div>
            <div className={styles.date_panel}>26</div>
            <div className={styles.date_panel}>27</div>
            <div className={styles.date_panel}>28</div>
            <div className={styles.date_panel}>29</div>
            <div className={styles.date_panel}>30</div>
            <div className={styles.date_panel}>31</div>

            <ViewportSlot>
              <div
                className={styles.shadow_top}
                style={{
                  height: "40px",
                }}
              ></div>
              <div
                className={styles.shadow_bottom}
                style={{
                  height: "50px",
                }}
              ></div>
              <div
                className={styles.date_panel_border_1}
                style={{
                  width: "100%",
                  left: "0px",
                  top: "60px",

                }}
              />
            </ViewportSlot>
          </Flicking>
          <Flicking
            className={styles.date_flicking}
            horizontal={false}
            onReady={updateTransform}
            onMove={updateTransform}
            defaultIndex={4}
          >
            <div className={styles.date_panel}>day(s)</div>
            <div className={styles.date_panel}>week(s)</div>
            <div className={styles.date_panel}>year(s)</div>

            <ViewportSlot>
              <div
                className={styles.shadow_top}
                style={{
                  height: "40px",
                }}
              ></div>
              <div
                className={styles.shadow_bottom}
                
              ></div>
              <div
                className={styles.date_panel_border_2}
                style={{
                  width: "100%",
                  left: "0px",
                  top: "60px",

                }}
              />
            </ViewportSlot>
          </Flicking>
        </div>
        <DefaultButton
          type="button"
          text="Start for $100"
          onClick={() => {}}
          //   isDisabled={isModalOpen}
          customStyles="font-s16 full-width btn-prediction"
        />
        <div
          className="flex padding-b10 "
          style={{ gap: "4px", marginTop: "10px" }}
        >
          <img src={varified} alt="" />
          <p className="text-uppercase t-align-l brownish-gray-text fMontserrat font-s8">
            Your personal data is safe with us. We’ll use your email for
            updates, receipts and subscription details
          </p>
        </div>
      </div>
    );
  };

  const userZodiac = zodiacsArray.filter(
    (item) => item.title === userData.zodiacName
  )[0];

  const pressButton = () => {
    scrollableContainer &&
      scrollableContainer.scrollIntoView({
        behavior: "smooth",
      });
    setSubscriptionType("62fca830-750c-4784-8482-7a3799d55d4a");
    toggleButtonModal(false);
    setSubscriptionData(subscriptionsList[1]);
    setIsOpenStripe(true);
  };

  return (
    <Layout>
      <div
        className={`app-container ${styles.appContainer}`}
        id="main-container"
        style={{ position: "relative" }}
      >

        <div className={styles.toast} >
          Your discount will expire in {<Timer time={600} />}
        </div>
        <div className="t-align-l px6 padding-h16" id="payment-container">
          <img src={LogoIcon} alt="" style={{ width: 135 }} />
        </div>
        <img src={cloudsImage} alt="" style={{ width: "100%" }} />

        <Elements stripe={stripePromise}>
          <div className="padding-content-h">
            <h2>
              Please <span className="gradient-pink-text"> select </span> a plan
            </h2>

            <div
              className="text"
              style={{
                fontFamily: "Golos",
                marginTop: 4,
              }}
            >
              {" "}
              Your discount will expire in 10:00{" "}
            </div>

            <div className={classNames(styles.pricingStackCardsContainer)}>
              {subscriptionsList.map((subscription) => {
                return (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "-8px",
                        left: "0px",
                        width: "100%",
                        zIndex: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {subscription.label && (
                        <div
                          style={{
                            fontSize: "9px",
                            lineHeight: "9px",
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                            color: "#EDECF7",
                            zIndex: 1,
                            padding: "4px 6px",
                            borderRadius: "6px",
                            background:
                              "linear-gradient(to right, #5784F8, #F5B6FF)",
                            textTransform: "uppercase",
                          }}
                        >
                          {subscription.label}
                        </div>
                      )}
                    </div>
                    <div
                      key={subscription.id}
                      className={classNames(
                        styles.card,
                        styles[
                          subscriptionType === subscription.id
                            ? "mostPopular"
                            : ""
                        ]
                      )}
                      onClick={() => {
                        if (!isModalOpen) {
                          setSubscriptionType(subscription.id);
                          // setPriceId(subscription.priceId);
                          toggleButtonModal(false);
                          setSubscriptionData(subscription);
                          setIsOpenStripe(true);
                        }
                      }}
                    >
                      {/* {subscription.label && (
                      <div
                        className={classNames(styles.gradientBGMsg)}
                        style={{ display: "none" }}
                      >
                        {subscription.label}
                      </div>
                    )} */}
                      {/* {subscriptionsList[4] && (
                      <div
                        className={classNames(styles.gradientBGMsg2)}
                        style={{ display: "none" }}
                      >
                        {subscriptionsList[4].label}
                      </div>
                    )} */}
                      <div className={classNames(styles.duration)}>
                        {subscription.period}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px",
                        }}
                      >
                        <div className={classNames(styles.price)}>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            $
                          </span>
                          {subscription.price}
                        </div>
                        <div className={classNames(styles.decimalPrice)}>
                          00
                        </div>
                      </div>
                      <div className={classNames(styles.priceByDay)}>
                        {subscription.perDay}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div
                className={classNames(styles.card)}
                onClick={() => setIsOpenCustomPlan((prev) => !prev)}
              >
                <img src={Add} />
                <div
                  className={classNames(styles.price)}
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  Add your custom plan
                </div>
              </div>
            </div>

            <div className="mt32 full-width" ref={trialBtnRef}>
              <DefaultButton
                type="button"
                text="Start Trial"
                onClick={() => {
                  console.log("open stripe");
                  toggleButtonModal(false);
                  setTimeout(() => setIsOpenStripe(true), 100);
                }}
                isDisabled={isModalOpen}
                customStyles="font-s16 full-width btn-prediction"
              />

              {isStripeOpen && (
                <StripeModal
                  isOpen={true}
                  paymentType="payment"
                  setIsOpenStripe={setIsOpenStripe}
                  toggleButtonModal={toggleButtonModal}
                  subscriptionData={subscriptionData}
                />
              )}
            </div>
            <div className="fRoboto font-s10 font-w400 text-color-silver t-align-l flex gap6 justify-content-start i-align-s mt8">
              <label className={styles.checkbox_container}>
                <input
                  type="checkbox"
                  id="myCheckbox"
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                <span className={styles.checkmark}>
                  {checked && (
                    <img
                      src={Checkmark}
                      alt="Checkmark"
                      width="10"
                      height="10"
                    />
                  )}
                </span>
              </label>
              <p>
                By continuing I accept
                <span className="gradient-pink-text pointer">
                  {" "}
                  Privacy Policy
                </span>
                ,<span className="gradient-pink-text"> Terms of Use</span>,
                <span className="gradient-pink-text"> Billing Terms</span> and
                <span className="gradient-pink-text"> Money-back Policy</span>.
              </p>
            </div>

            <div className="flex justify-content-center i-align-s gap6 font-s14 text mt24">
              <img src={safetyIcon} />
              Guaranteed safe checkout
            </div>

            <div className="flex justify-content-center i-align-s gap6 font-s14 text mt24">
              <img src={Visa} />
              <img src={MasterCard} />
              <img src={AmericanExpress} />
              <img src={PayPal} />
            </div>

            <h1 className="mt40">
              Meet Our <span className="gradient-pink-text">Services</span>
            </h1>

            <img src={ServicesCarouselPic} className="mt12" />

            <h1 className="mt40" style={{ textAlign: "left", marginLeft: 5 }}>
              What People Are Saying
            </h1>
            <div className={classNames(styles.tastimonialWrapper, "text")}>
              <img
                src={Quotes}
                className={classNames(styles.quotes1)}
                width={18}
                height={18}
              />
              <img
                src={Quotes}
                className={classNames(styles.quotes2)}
                width={18}
                height={18}
              />
              Fateful's Palm Reading feature is incredibly accurate and
              detailed. The Love Compatibility section is fun and surprisingly
              insightful. I love using the Biorhythms tool to plan my day, and
              the Tarot Reading adds a mystical touch with its profound
              guidance.
            </div>
          </div>
        </Elements>

        <img
          src={Banner}
          alt=""
          style={{
            width: "100%",
            padding: "14px 0px 40px 0px",
          }}
        />

        <div className="padding-content-h">
          <Card />
          <ReadMore />

          <Footer />

          {paymentLocation === "registration" && (
            <EmailModal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              toggleButtonModal={toggleButtonModal}
            />
          )}

          <CustomHeightBottomSheet
            modalHeight={120}
            renderBackdrop={false}
            header={buttonModalHeader}
            isModalOpen={isButtonModalOpen}
            setOpen={toggleButtonModal}
          />

          <CustomHeightBottomSheet
            modalHeight={380}
            renderBackdrop={true}
            header={CustomModalHeader}
            content={CustomModalContent}
            isModalOpen={isOpenCustomPlan}
            setOpen={() => setIsOpenCustomPlan((prev) => !prev)}
          />
        </div>
      </div>
      <Loader />
    </Layout>
  );
};

export default PaymentNewPage;

const ReadMore: React.FC = () => {
  const renderText = () => (
    <div>
      <p>
        YOUR TRIAL OPTIONS INCLUDE: A $1 3-DAY TRIAL, AFTER WHICH A $18.99 FEE
        APPLIES BI-WEEKLY; A $3.55 WEEK-LONG TRIAL, FOLLOWED BY $18.99 EVERY TWO
        WEEKS; OR A PREMIUM $9.99 TRIAL LEADING TO A MONTHLY $28.99 CHARGE.
        THESE SUBSCRIPTIONS COMMENCE AUTOMATICALLY POST-TRIAL UNLESS CANCELLED
        WITHIN THE APP OR BY CONTACTING CUSTOMER SUPPORT AT LEAST 24 HOURS PRIOR
        TO THE NEXT BILLING CYCLE. CANCELLATION IS FEASIBLE AT ANY TIME THROUGH
        THE SAME CHANNELS. BEFORE SEEKING A REFUND, PLEASE REVIEW OUR{" "}
        <span className="gradient-pink-text">MONEY-BACK GUARANTEE</span> POLICY.
        FOR FURTHER ASSISTANCE OR INQUIRIES, CONTACT US AT{" "}
        <span className="gradient-pink-text">ADMIN@FATEFUL</span>. ENSURE
        FAMILIARITY WITH OUR TERMS TO FULLY UNDERSTAND YOUR SUBSCRIPTION DETAILS
        AND RIGHTS.
      </p>
    </div>
  );

  return (
    <div>
      <ReadMoreComponent renderText={renderText} />
    </div>
  );
};

const Footer: React.FC = () => {
  return (
    <div
      className="flex justify-content-start i-align-s text-color-silver flex-column fMontserrat font-s16 font-w400 padding-t40"
      style={{ paddingBottom: 120 }}
    >
      <img src={LogoIcon} alt="" style={{ width: 135 }} />

      <div className="mt32 mb40 flex i-align-s">
        <div
          className="t-align-l flex flex-column gap16 font-w600"
          style={{ marginRight: 48 }}
        >
          <p>About us</p>
          <p>Blog</p>
          <p>Contact us</p>
          <p>FAQ</p>
        </div>
        <div className="t-align-l gap16 flex flex-column font-w600">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
          <p>Billing Terms</p>
        </div>
      </div>
      <div className="flex i-align-c gap16" style={{ margin: "0 auto" }}>
        <XAppIcon /> <InstagramIcon />
        <FaceBookIcon />
      </div>
    </div>
  );
};

const Card = () => {
  return (
    <div className={classNames(styles.moneyBackGuaranteeCard)}>
      <img
        src={GuranteeFaded}
        alt=""
        className={classNames(styles.stickedFadedGuranteeBadge)}
      />

      <h3>
        {" "}
        <span className="gradient-blue-text">100% </span> Money-back guarantee
      </h3>
      <div
        className=" text mt4"
        style={{
          fontFamily: "Golos",
        }}
      >
        At Fateful, our goal is to offer the best. If you don't see progress
        within the first week, we'll refund you in{" "}
        <span className="gradient-blue-text"> 21 days. </span>
      </div>
      <div
        className="font-s10 font-w600 brownish-gray-text mt8 fGolos"
        style={{
          textTransform: "uppercase",
        }}
      >
        Learn more about the specific constraints outlined <br /> in our{" "}
        <span className="gradient-pink-text">
          {" "}
          Money-Back Guarantee Policy.{" "}
        </span>{" "}
      </div>
    </div>
  );
};
