import React, { FC } from "react";
import styles from "./ResultCard.module.scss";

type ResultCardProps = {
  title: string;
  cardName: string;
  card: string;
  description: string;
};
const ResultCard: FC<ResultCardProps> = ({
  card,
  title,
  cardName,
  description,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={card} alt={""} style={{ width: "23.91%" }} />
        <div className="flex  flex-column i-align-s gap4">
          <h3>{title}</h3>
          <p className="gradient-pink-text text-uppercase font-s8 fGolos font-w600">
            {cardName}
          </p>
        </div>
      </div>
      <div>
        <p className={"font-s14 mb8 font-w600 gradient-pink-text fGolos"}>Overview</p>
        <p className={"font-s14 brownish-gray-text fLora"}>{description}</p>
      </div>
    </div>
  );
};

export default ResultCard;
