import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import styles from "./LuckyLettersPage.module.scss";
import LuckyCard from "components/cards/LuckyCard/LuckyCard";
import { useAppSelector } from "store";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const LuckyLettersPage = () => {
  const { luckyAlphabets } = useAppSelector(
    (state) => state.horoscope.daily.prediction,
  );
  const content = () => {
    return <p className={styles.letters}>{luckyAlphabets.join(", ")}</p>;
  };

  useEffect(() => {
    ReactPixel.track("Lucky Letters Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/lucky-letters",
      title: "Lucky Letters Page",
    });
  }, []);

  return (
    <Layout>
      <div className="container" style={{
          backgroundImage: "url(./assets/background.svg)",
          backgroundPosition: 'center center',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}>
        <HeaderWithBack />
        <div className={styles.content}>
          <h1 className="fGolos">Letters</h1>
          <LuckyCard
            content={content}
            text={
              "Today, Aries, the stars align with an extra dose of enthusiasm and action, and the letters A and K take center stage in your cosmic narrative. A is the beacon of assertiveness and ambition, urging you to boldly pursue your goals. K, a symbol of kinetic energy, infuses your spirit with vitality, propelling you towards exciting ventures. Embrace the adventurous vibes brought by these letters, and let the cosmic dance of A and K guide you to new heights. Seize the day with confidence, ignite your passions, and watch as luck unfolds in alignment with your spirited pursuits!"
            }
          />
        </div>
      </div>
    </Layout>
  );
};
export default LuckyLettersPage;
