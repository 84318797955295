import React from "react";

const HeartsIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6475 10.6371C11.9425 10.5481 12.2596 10.496 12.5967 10.4877C13.1587 10.474 13.7747 10.5824 14.4293 10.8448C16.571 11.7034 17.7884 13.9677 17.5439 16.6534C17.2994 19.339 15.6563 22.4718 12.1826 25.3819C12.057 25.4872 11.8919 25.5326 11.7301 25.506C7.31105 24.7824 4.36145 22.9151 2.84855 20.7105C1.33567 18.5058 1.30438 15.925 2.73652 14.0835C4.13872 12.2808 5.95291 12.1615 7.24642 12.5762C8.19412 12.8801 8.52703 13.2378 8.85259 13.5056C9.0028 13.1098 9.11659 12.6204 9.79078 11.8665C10.2484 11.3549 10.8608 10.8746 11.6475 10.6371Z" fill="url(#paint0_linear_186_12675)"/>
<path d="M23.2341 10.4877C22.7188 10.4977 22.161 10.6087 21.5719 10.8448C19.4301 11.7032 18.2083 13.9675 18.4528 16.6534C18.6972 19.3391 20.3448 22.4719 23.8186 25.3819C23.9442 25.4872 24.1094 25.5326 24.2713 25.506C28.69 24.7825 31.6354 22.9151 33.1483 20.7105C34.6612 18.5058 34.6969 15.9249 33.2648 14.0835C31.8626 12.2807 30.0485 12.1615 28.7549 12.5762C27.807 12.8801 27.4742 13.2378 27.1487 13.5056C26.9985 13.1099 26.8804 12.6205 26.206 11.8665C25.7485 11.3549 25.1404 10.8745 24.3538 10.6371C24.0096 10.5333 23.6348 10.48 23.2341 10.4877Z" fill="url(#paint1_linear_186_12675)"/>
<defs>
<linearGradient id="paint0_linear_186_12675" x1="0.654818" y1="30.0215" x2="22.3863" y2="22.0301" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint1_linear_186_12675" x1="17.389" y1="30.0214" x2="39.1228" y2="22.0277" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
</defs>
</svg>

);

export default HeartsIcon;
