import React, { ReactNode, useEffect, useState } from "react";
import HeartsIcon from "assets/svg/hearts";
import BrokenHeartIcon from "assets/svg/broke-heart";
import EngagementIcon from "assets/svg/engagement";
import MarriedIcon from "assets/svg/married";
import LookingForLoveIcon from "assets/svg/looking-for-love";
import ConfusedIcon from "assets/svg/confused";
import StepsTemplate from "../StepsTemplate";
import styles from "./RelationshipSelect.module.scss";
import { useAppDispatch, useAppSelector } from "store";
import { setRelationshipStatus } from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import HorizontalCard from "./components/HorizentalCard";

type Relationship = {
  type: string;
  icon: () => ReactNode;
  id: number;
};

const RelationshipList: Relationship[] = [
  { type: "In a relationship", icon: () => <HeartsIcon />, id: 0 },
  { type: "Broke up", icon: () => <BrokenHeartIcon />, id: 1 },
  { type: "Engaged", icon: () => <EngagementIcon />, id: 2 },
  { type: "Married", icon: () => <MarriedIcon />, id: 3 },
  { type: "Looking for a soulmate", icon: () => <LookingForLoveIcon />, id: 4 },
  { type: "It’s complicated", icon: () => <ConfusedIcon />, id: 5 },
];

const RelationshipSelect = () => {
  const userRelationshipStatus = useAppSelector(
    (state) => state.registration.relationshipStatus
  );
  const dispatch = useAppDispatch();
  const onSetRelationshipStatus = (type: string) => {
    ReactGA.event("relationshipStatusSelected");
    ReactPixel.trackCustom("relationshipStatusSelected");
    dispatch(setRelationshipStatus(type));
  };

  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <StepsTemplate
      lightTitle="Tell us about your current "
      yellowTitle="relationship"
    >
      <div
        className={styles.cardsContainer}
        style={{ marginTop: innerHeight < 600 ? 14 : 24 }}
      >
        {RelationshipList.map((item) => (
          <HorizontalCard
            type={item.type}
            icon={item.icon}
            key={item.id}
            isSelected={userRelationshipStatus === item.type}
            setType={onSetRelationshipStatus}
          />
        ))}
      </div>
    </StepsTemplate>
  );
};

export default RelationshipSelect;
