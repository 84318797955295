import React, { useEffect, useRef, useState } from "react";
import StepsTemplate from "../StepsTemplate";

import { useAppDispatch, useAppSelector } from "store";
import { dates, getZodiacSign } from "utils/defaults";
import { setBirthday } from "store/slices/registration";
import DefaultButton from "components/buttons/DefaultButton";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";

import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import Style from "./styles.module.scss";
import { isLeapYear } from "utils/validations";
import { createDateFormat } from "utils/dates";
import { shortMonthMap } from "constants/date";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const BirthDaySelect = () => {
  const flickingRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const userBirthday = useAppSelector(
    (state) => state.registration.birthday?.split("/")
  );
  console.log("userBirthday", userBirthday);

  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [daysCount, setDaysCount] = useState(31);
  const [dayIndex, setDayIndex] = useState(0);

  const onSelectBirthday = () => {
    ReactGA.event("birthdaySelected");
    ReactPixel.trackCustom("birthdaySelected");
    const date = createDateFormat(month, day, year);
    const zodiac = getZodiacSign(shortMonthMap[month], +day);
    dispatch(
      setBirthday({
        birthday: date,
        zodiac: zodiac.data.zodiacIconUrl,
        zodiacName: zodiac.type,
      })
    );
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        if (panel.element.textContent.length === 4) {
          if (isLeapYear(panel.element.textContent) && month === "february") {
            if (flickingRef.current) flickingRef.current.moveTo(0);
          }
          setYear(panel.element.textContent);
        } else if (panel.element.textContent.length <= 2) {
          setDay(panel.element.textContent);
          setDayIndex(0);
        } else {
          setMonth(panel.element.textContent?.toLowerCase());
        }
      }
    });
  };

  useEffect(() => {
    if (
      [
        "april",
        "january",
        "march",
        "may",
        "july",
        "august",
        "october",
        "december",
      ].some((el) => el === month)
    ) {
      setDaysCount(31);
    } else if (month === "february") {
      setDaysCount(isLeapYear(+year) ? 29 : 28);
    } else {
      setDaysCount(30);
    }
    if (flickingRef.current) flickingRef.current.moveTo(0);
  }, [month]);

  return (
    <StepsTemplate
      lightTitle="When’s your "
      yellowTitle="Birthday?"
      text="Adding your birthday enriches astrological insights for accuracy and depth."
    >
      <div
        className={Style.date_demo_wrapper}
        style={{ gap: "24px" }}
      >
        <Flicking
          ref={flickingRef}
          defaultIndex={dayIndex}
          className={Style.date_flicking}
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
        >
          <div className={Style.date_panel}>1</div>
          <div className={Style.date_panel}>2</div>
          <div className={Style.date_panel}>3</div>
          <div className={Style.date_panel}>4</div>
          <div className={Style.date_panel}>5</div>
          <div className={Style.date_panel}>6</div>
          <div className={Style.date_panel}>7</div>
          <div className={Style.date_panel}>8</div>
          <div className={Style.date_panel}>9</div>
          <div className={Style.date_panel}>10</div>
          <div className={Style.date_panel}>12</div>
          <div className={Style.date_panel}>13</div>
          <div className={Style.date_panel}>14</div>
          <div className={Style.date_panel}>15</div>
          <div className={Style.date_panel}>16</div>
          <div className={Style.date_panel}>17</div>
          <div className={Style.date_panel}>18</div>
          <div className={Style.date_panel}>19</div>
          <div className={Style.date_panel}>20</div>
          <div className={Style.date_panel}>21</div>
          <div className={Style.date_panel}>22</div>
          <div className={Style.date_panel}>23</div>
          <div className={Style.date_panel}>24</div>
          <div className={Style.date_panel}>25</div>
          <div className={Style.date_panel}>26</div>
          <div className={Style.date_panel}>27</div>
          <div className={Style.date_panel}>28</div>
          <div className={Style.date_panel}>29</div>
          <div className={Style.date_panel}>30</div>
          <div className={Style.date_panel}>31</div>
          <ViewportSlot>
            <div
              className={Style.shadow_top}
              
            ></div>
            <div
              className={Style.shadow_bottom}
              
            ></div>
            <div
              className={Style.date_panel_border}
              // style={{
              //   width: "100%",
              //   top: "100px",
              // }}
            />
          </ViewportSlot>
        </Flicking>
        <Flicking
          className={Style.date_flicking}
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
          defaultIndex={4}
        >
          <div className={Style.date_panel}>January</div>
          <div className={Style.date_panel}>February</div>
          <div className={Style.date_panel}>March</div>
          <div className={Style.date_panel}>April</div>
          <div className={Style.date_panel}>May</div>
          <div className={Style.date_panel}>June</div>
          <div className={Style.date_panel}>July</div>
          <div className={Style.date_panel}>August</div>
          <div className={Style.date_panel}>September</div>
          <div className={Style.date_panel}>October</div>
          <div className={Style.date_panel}>November</div>
          <div className={Style.date_panel}>December</div>
          <ViewportSlot>
            <div
              className={Style.shadow_top}
              
            ></div>
            <div
              className={Style.shadow_bottom}
              
            ></div>
            <div
              className={Style.date_panel_border}
              
            />
          </ViewportSlot>
        </Flicking>
        <Flicking
          defaultIndex={50}
          className={Style.date_flicking}
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
        >
            <div className={Style.date_panel}>1950</div>
            <div className={Style.date_panel}>1951</div>
            <div className={Style.date_panel}>1952</div>
            <div className={Style.date_panel}>1953</div>
            <div className={Style.date_panel}>1954</div>
            <div className={Style.date_panel}>1955</div>
            <div className={Style.date_panel}>1956</div>
            <div className={Style.date_panel}>1957</div>
            <div className={Style.date_panel}>1958</div>
            <div className={Style.date_panel}>1959</div>
            <div className={Style.date_panel}>1960</div>
            <div className={Style.date_panel}>1961</div>
            <div className={Style.date_panel}>1962</div>
            <div className={Style.date_panel}>1963</div>
            <div className={Style.date_panel}>1964</div>
            <div className={Style.date_panel}>1965</div>
            <div className={Style.date_panel}>1966</div>
            <div className={Style.date_panel}>1967</div>
            <div className={Style.date_panel}>1968</div>
            <div className={Style.date_panel}>1969</div>
            <div className={Style.date_panel}>1970</div>
            <div className={Style.date_panel}>1971</div>
            <div className={Style.date_panel}>1972</div>
            <div className={Style.date_panel}>1973</div>
            <div className={Style.date_panel}>1974</div>
            <div className={Style.date_panel}>1975</div>

          <ViewportSlot>
            <div
              className={Style.shadow_top}
             
            ></div>
            <div
              className={Style.shadow_bottom}
              
            ></div>
            <div
              className={Style.date_panel_border}
              
            />
          </ViewportSlot>
        </Flicking>

        {/* <div className="flex position-absolute date-panel-container">
          <div
            className={Style.date_panel_border_2}
            style={{
              left: "130px",
              width: "110px",
            }}
          />
          <div
            className="date-panel-border-3"
            style={{
              left: "250px",
            }}
          />
        </div> */}
      </div>
      <div className={Style.bottom_container_absolute}>
        <DefaultButton text={"Next"} type="button" onClick={onSelectBirthday} />
      </div>
    </StepsTemplate>
  );
};

export default BirthDaySelect;
