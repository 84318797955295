import React, { useEffect, useState } from "react";
import Ellipse1 from "assets/svg/ellipses/Ellipse 241-1.svg";
import Ellipse2 from "assets/svg/ellipses/Ellipse 241.svg";
import Ellipse3 from "assets/svg/ellipses/Ellipse 241-2.svg";
import Ellipse4 from "assets/svg/ellipses/Ellipse4.svg";
import LogoIcon from "../../assets/svg/logoIcon.svg";
import styles from "./AnimationPage.module.scss";
import { useAppSelector } from "store";
import CustomProgressBar from "components/progressBar/ProgressBar";
import moment from "moment";
import AnalysingAnimation from "./AnalysingAnimation";
import { useNavigate } from "react-router-dom";
import { setPaymentLocation } from "../../helpers/sessionStorageHelpers";

import LogRocket from "logrocket";
LogRocket.init("nwxyyv/astroday");

const AnimationPage = () => {
  const { userData } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState({
    key: "gender",
    value: userData?.genderType,
  });
  const [isShowPalm, setShowPalm] = useState(false);

  useEffect(() => {
    const totalTime = 10; // Total duration in seconds
    let currentTime = 0; // Current time elapsed, starts from 0

    const interval = setInterval(() => {
      currentTime++;
      const calculatedProgress = (currentTime / totalTime) * 100;
      setProgress(+calculatedProgress.toFixed(0));
      if (calculatedProgress >= 14) {
        setData({
          key: "Birthday",
          value: moment(userData?.birthDayDate).format("DD.MM.YYYY"),
        });
      }
      if (calculatedProgress >= 28) {
        setData({
          key: "Birthplace",
          value: userData.placeOfBirth,
        });
      }
      if (calculatedProgress >= 42) {
        setData({
          key: "Relationship",
          value: userData.relationshipStatus,
        });
      }
      if (calculatedProgress >= 54) {
        setData({
          key: "Natural element",
          value: userData.naturalElement,
        });
      }
      if (calculatedProgress >= 68) {
        setData({
          key: "Prefer color",
          value: userData.preferColor,
        });
      }
      if (calculatedProgress >= 84) {
        setShowPalm(true);
      }
      // Check if progress reaches 100%
      if (calculatedProgress >= 100) {
        clearInterval(interval);
        setPaymentLocation("analysing");
        navigate("/payment");
      }
    }, 1000); // Repeat every second

    return () => clearInterval(interval); // Cleanup function to clear the interval
  }, []);

  return (
    <div className="container overflow-hidden">
      <img
        src={Ellipse1}
        alt=""
        className="position-absolute"
        style={{ left: -90, zIndex: -1 }}
      />
      <img
        src={Ellipse2}
        alt=""
        className="position-absolute"
        style={{ top: -90, zIndex: -1 }}
      />
      <img
        src={Ellipse3}
        alt=""
        className="position-absolute"
        style={{ top: 190, zIndex: -1 }}
      />
      <img
        src={Ellipse1}
        alt=""
        className="position-absolute"
        style={{ left: -90, top: 290, zIndex: -1 }}
      />
      <img
        src={Ellipse2}
        alt=""
        className="position-absolute"
        style={{ top: 400, left: 20, zIndex: -1 }}
      />
      <div className="flex">
        <img src={LogoIcon} alt="" style={{ width: 100 }} />
      </div>
      <h1 className="mt32">
        {isShowPalm ? (
          <>
            Generating your
            <span className="gradient-pink-text"> palm reading</span> report...
          </>
        ) : (
          <>
              <span className="gradient-pink-text">Analysing</span> your information...
          </>
        )}
      </h1>
      {isShowPalm ? (
        <AnalysingAnimation />
      ) : (
        <div
          className="position-relative flex i-align-c flex-column justify-content-center"
          style={{ marginTop: 70, height: 204, padding: 32 }}
        >
          <div className={styles.circle}></div>
          <img
            src={Ellipse4}
            alt=""
            className="position-absolute"
            style={{ top: -11, zIndex: -1 }}
          />
          <p
            className="fMontserrat text-uppercase font-s14 text-color-silver"
            style={{ width: 204 }}
          >
              <span className="gradient-pink-text">{data.key}: </span> {data.value}
          </p>
        </div>
      )}
      <div style={{ marginTop: isShowPalm ? 16 : 150, zIndex: 10 }}>
        <CustomProgressBar
          completed={progress}
          maxCompleted={100}
          customBarWrapperStyles={styles.progressBarWrapper}
          customBarContainerStyles={
            "progress-bar-container-electric-blue height6"
          }
        />
        <p className="text-white font-s20 fMontserrat font-w600 mt16">
          {progress}%
        </p>
      </div>
    </div>
  );
};

export default AnimationPage;
