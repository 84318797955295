import React from "react";
import styles from "./CertificateCard.module.scss";
import NounGuaranteeIcon from "assets/svg/noun-guarantee";
import classNames from "classnames";

const CertificateCard = () => {
  return (
    <div
      className={classNames(
        "flex flex-column padding-h18 padding-v24 border-radius12 i-align-c fMontserrat text-color-silver gap8",
        styles.container,
      )}
    >
      <NounGuaranteeIcon />
      <h3>
        <span className="yellow-text">100%</span> Money-back guarantee
      </h3>
      <p className="fRoboto font-s16 font-w400">
        At Fateful, our goal is to offer the best. If you don't see progress
        within the first week, we'll refund you in
        <span className="yellow-text"> 21 days.</span>
      </p>
      <p className="text-white font-s10 text-uppercase mt8">
        Learn more about the specific constraints outlined in our
        <span className="text-color-blue"> Money-Back Guarantee Policy.</span>
      </p>
    </div>
  );
};

export default CertificateCard;
