import React, { useEffect } from "react";
import CircleProgressBar from "components/CircleProgressBar/CircleProgressBar";
import classNames from "classnames";
import DefaultButton from "components/buttons/DefaultButton";
import CameraComponent from "components/CameraComponent/CameraComponent";
import PalmLines from "assets/svg/palmistry-hand.svg";
import { useCamera } from "hooks/useCamera";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const PalmPhotoAndAnalyzing = () => {
  const navigate = useNavigate();
  const { palmFile } = useLocation().state;
  const { imageData, captureImage, startCamera } = useCamera();

  useEffect(() => {
    if (!palmFile) {
      startCamera();
    }
    ReactPixel.track("Palm Scan And Analyzing Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/palm-scan-and-analyzing",
      title: "Palm Scan And Analyzing Page",
    });
  }, []);

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          {imageData || palmFile ? (
            <>
              <div
                className="flex i-align-c overflow-hidden border-radius12 default-border"
                style={{ width: "66.567%", height: 296, margin: "0 auto" }}
              >
                <img
                  src={imageData || palmFile}
                  alt={""}
                  className="full-width full-height"
                />
              </div>
              <div className={"flex i-align-c flex-column mt8"}>
                <CircleProgressBar />
                <p className={"font-w500 fLora brownish-gray-text mt8"}>
                  We are now scanning your fingers and palm lines.
                  <span className={"gradient-pink-text"}>
                    {" "}
                    Please hold on a moment{" "}
                  </span>
                  for your personalized insights.
                </p>
              </div>
              <div className={classNames("text", "bottom-container-absolute")}>
                <DefaultButton
                  text="Next"
                  type="button"
                  onClick={() => navigate("/")}
                />
              </div>
            </>
          ) : (
            <div>
              {/*<div className={styles.container}>*/}
              <div
                className="border-radius12 default-border full-width overflow-hidden position-relative"
                style={{
                  background: "transparent",
                  height: 445,
                  backdropFilter: "blur(3px)",
                }}
              >
                <CameraComponent />
                <img
                  src={PalmLines}
                  alt={""}
                  className="position-absolute absolute-left0 absolute-right0"
                  style={{
                    top: 0,
                    bottom: 0,
                    width: "74.626%",
                    height: 337.5,
                    margin: "auto auto",
                  }}
                />
              </div>
              <div className={classNames("text", "bottom-container-absolute")}>
                <DefaultButton
                  text="Take a Photo"
                  type="button"
                  onClick={async () => {
                    await captureImage();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PalmPhotoAndAnalyzing;
