import React from "react";
const FaceBookIcon = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.26318" width="30" height="30" rx="7" fill="#EDECF7" />
    <rect x="1.5" y="1.26318" width="30" height="30" rx="7" stroke="#D3D3D3" stroke-width="2" />
    <g clip-path="url(#clip0_7385_8436)">
      <path d="M17.8849 10.5446H20.0326V7.01416H17.5425C14.0252 7.01416 14.1186 10.1033 14.1186 10.5446V12.853H12.5V16.3835H14.1186C14.1186 20.2535 14.1186 24.5987 14.1186 24.5987H17.3246C17.3246 24.5987 17.3246 20.1856 17.3246 16.3835H19.4723L20.0015 12.853H17.3246V11.0878C17.3557 10.7144 17.5736 10.5446 17.8849 10.5446Z" fill="url(#paint0_linear_7385_8436)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_7385_8436" x1="13.5734" y1="31.6325" x2="31.483" y2="24.6264" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <clipPath id="clip0_7385_8436">
        <rect width="8" height="19" fill="white" transform="translate(12.5 6.26318)" />
      </clipPath>
    </defs>
  </svg>

);

export default FaceBookIcon;
