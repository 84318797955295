import React, { FC, useRef, useEffect, useState } from "react";
import styles from "./HoroscopeInfographicItem.module.scss";
import ArrowRight from "assets/svg/arrow-right";
import { Pie } from "react-chartjs-2";

type HoroscopeInfographicItemProps = {
  onPressHoroscopeInfographic: (id: string) => void;
  horoscopeInfographic: any;
};

const HoroscopeInfographicItem: FC<HoroscopeInfographicItemProps> = ({
  onPressHoroscopeInfographic,
  horoscopeInfographic,
}) => {
  const chartRef = useRef(null);
  const [gradient, setGradient] = useState<string | CanvasGradient>("");

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      // Create a linear gradient
      const newGradient = ctx.createLinearGradient(0, 0, chart.width, chart.height);
      newGradient.addColorStop(0, "#5784F8");
      newGradient.addColorStop(1, "#F5B6FF");
      setGradient(newGradient);
    }
  }, [chartRef]);


  const newData = [
    { ...horoscopeInfographic.love, title: "Love" },
    { ...horoscopeInfographic.health, title: "Health" },
    { ...horoscopeInfographic.career, title: "Career" },
  ];

  return (
    <>
      {newData.map((item: any, index) => (
        <div
          key={index}
          className={styles.infographicContainer}
          onClick={() => onPressHoroscopeInfographic(item.title)}
        >
          <p className={styles.title}>
            {item.title}
            <ArrowRight />
          </p>
          <div className={styles.pieContainer}>
            <div className={styles.container}>
              <Pie
                ref={chartRef}
                data={{
                  datasets: [
                    {
                      data: [100 - item.percent, item.percent],
                      backgroundColor: ["#EDECF7", gradient],
                      borderWidth: 0,
                    },
                  ],
                }}
              />
            </div>
            <p className={styles.text}>{item.percent}%</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default HoroscopeInfographicItem;
