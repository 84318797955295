import React from "react";

const XAppIcon = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.26318" width="30" height="30" rx="7" fill="#EDECF7" />
    <rect x="1.5" y="1.26318" width="30" height="30" rx="7" stroke="#D3D3D3" stroke-width="2" />
    <g clip-path="url(#clip0_7385_8428)">
      <path d="M17.8319 15.1168L23.0437 9.05847H21.8087L17.2833 14.3188L13.6688 9.05847H9.5L14.9657 17.013L9.5 23.3661H10.7351L15.5141 17.811L19.3312 23.3661H23.5L17.8316 15.1168H17.8319ZM16.1403 17.0831L15.5865 16.291L11.1801 9.98824H13.0772L16.6331 15.0748L17.1869 15.8669L21.8093 22.4786H19.9122L16.1403 17.0834V17.0831Z" fill="url(#paint0_linear_7385_8428)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_7385_8428" x1="11.495" y1="29.0892" x2="32.8354" y2="10.0197" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <clipPath id="clip0_7385_8428">
        <rect width="14" height="15.9056" fill="white" transform="translate(9.5 8.26318)" />
      </clipPath>
    </defs>
  </svg>

);

export default XAppIcon;
