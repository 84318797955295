import React from "react";
const EngagementIcon = () => (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.08657 5.66467L0.914062 4.71207L0.984805 5.86234C1.00259 6.15085 1.21391 6.3865 1.4988 6.43532L2.54464 6.6147C2.78601 6.34216 3.04771 6.08425 3.32853 5.84282L2.66048 5.86003C2.65296 5.86028 2.64534 5.86036 2.63781 5.86036C2.43742 5.86036 2.24248 5.79136 2.0866 5.66468L2.08657 5.66467Z" fill="url(#paint0_linear_186_12685)"/>
<path d="M8.40014 18.021C7.43264 17.9775 6.48286 17.6994 5.64568 17.212C4.74571 16.6879 3.99425 15.9308 3.47265 15.0226C2.67652 13.6364 2.46784 12.0231 2.88503 10.48C3.30229 8.93681 4.29552 7.64844 5.68185 6.85222C7.06809 6.05609 8.68139 5.84734 10.2245 6.2646C11.7677 6.68186 13.056 7.675 13.8522 9.06142C14.6484 10.4477 14.8571 12.061 14.4399 13.604C14.0226 15.1471 13.0294 16.4356 11.643 17.2318C11.4775 17.327 11.3064 17.4139 11.1327 17.4929C11.5082 18.0145 11.9624 18.4541 12.469 18.8056C12.4887 18.7945 12.5083 18.784 12.528 18.7727C16.2392 16.6412 17.5245 11.8878 15.393 8.17659C13.2615 4.46535 8.5081 3.18005 4.79687 5.31156C1.08563 7.44307 -0.199457 12.1964 1.93184 15.9077C3.48613 18.6138 6.53101 20.1226 9.60404 19.7481C9.20854 19.3127 8.85713 18.8314 8.5572 18.3091C8.50251 18.2138 8.45055 18.1176 8.40008 18.0211L8.40014 18.021Z" fill="#827677"/>
<path d="M2.64821 5.44352L3.87319 5.41183C3.87427 5.41109 3.87526 5.41034 3.87625 5.40952L1.03706 2.06769L0.36298 3.23177C0.249541 3.42753 0.294718 3.67328 0.470377 3.81592L2.34794 5.34125C2.4325 5.41009 2.53849 5.44683 2.64812 5.4436L2.64821 5.44352Z" fill="url(#paint1_linear_186_12685)"/>
<path d="M5.96577 3.52214C6.03958 3.42409 6.07118 3.29817 6.05232 3.17678L5.68636 0.821377C5.65153 0.59772 5.46205 0.434897 5.23583 0.434241L3.89062 0.429855L5.29349 4.41518L5.96577 3.52214Z" fill="url(#paint2_linear_186_12685)"/>
<path d="M4.58714 4.94999C4.71192 4.87834 4.83785 4.81041 4.96477 4.74603L3.47924 0.526016L1.32422 1.76374L4.22081 5.17335C4.34029 5.09616 4.46241 5.02161 4.58719 4.94995L4.58714 4.94999Z" fill="url(#paint3_linear_186_12685)"/>
<path d="M6.29755 3.77272L5.85547 4.35994C6.25106 4.21514 6.65308 4.10254 7.05818 4.02186L7.43026 3.02814C7.53162 2.7575 7.43465 2.45615 7.19436 2.29547L6.23638 1.65472L6.463 3.1127C6.49891 3.34479 6.43868 3.58524 6.29752 3.77272H6.29755Z" fill="url(#paint4_linear_186_12685)"/>
<path d="M23.8394 22.4609L21.9618 20.9355C21.8773 20.8668 21.7697 20.8304 21.6615 20.8333L20.4366 20.8649C20.4356 20.8657 20.4346 20.8664 20.4336 20.8671L23.2726 24.2089L23.9467 23.0449C24.0601 22.8492 24.015 22.6035 23.8393 22.4608L23.8394 22.4609Z" fill="url(#paint5_linear_186_12685)"/>
<path d="M18.3458 22.7553C18.272 22.8533 18.2404 22.9793 18.2593 23.1007L18.6252 25.4563C18.66 25.6798 18.8495 25.8427 19.0757 25.8434L20.4209 25.8477L19.018 21.8624L18.3458 22.7553Z" fill="url(#paint6_linear_186_12685)"/>
<path d="M19.7216 21.3276C19.5971 21.3991 19.4708 21.4662 19.3438 21.5308L20.8295 25.7516L22.9848 24.5139L20.0882 21.1043C19.9686 21.1815 19.8465 21.256 19.7217 21.3277L19.7216 21.3276Z" fill="url(#paint7_linear_186_12685)"/>
<path d="M18.0123 22.5053L18.4532 21.9196C18.0597 22.0643 17.6578 22.1781 17.2503 22.26L16.8797 23.2499C16.7783 23.5206 16.8753 23.8219 17.1156 23.9827L18.0735 24.6233L17.8469 23.1653C17.8109 22.9333 17.8712 22.6928 18.0123 22.5053Z" fill="url(#paint8_linear_186_12685)"/>
<path d="M22.3814 10.3702C20.8267 7.66336 17.7801 6.1546 14.7064 6.53015C15.0999 6.96172 15.4532 7.44178 15.7559 7.96885C15.8106 8.06408 15.8625 8.16014 15.913 8.2567C16.8791 8.30006 17.8276 8.5775 18.6641 9.06377C19.5657 9.58793 20.3183 10.3457 20.8407 11.2552C21.6368 12.6415 21.8456 14.2548 21.4283 15.7978C21.0111 17.341 20.0178 18.6294 18.6315 19.4256C17.6945 19.9638 16.6711 20.2194 15.661 20.2194C13.5862 20.2195 11.5666 19.1411 10.4611 17.2165C8.81755 14.3549 9.80862 10.6896 12.6701 9.04612C12.8359 8.95097 13.007 8.86393 13.1807 8.78491C12.8106 8.26977 12.3605 7.82687 11.8479 7.4704C11.827 7.48215 11.806 7.4934 11.7852 7.50532C8.07401 9.63682 6.78871 14.3902 8.92022 18.1014C9.95274 19.8992 11.6236 21.1872 13.6249 21.7284C15.6265 22.2694 17.7186 21.9989 19.5163 20.9662C23.2274 18.835 24.5127 14.0814 22.3811 10.3701L22.3814 10.3702Z" fill="#827677"/>
<path d="M22.8141 19.8422L21.7683 19.6628C21.5269 19.9353 21.2652 20.1932 20.9844 20.4347L21.6524 20.4174C21.8619 20.4134 22.0646 20.4814 22.2262 20.6128L23.3987 21.5654L23.328 20.4152C23.3103 20.1267 23.099 19.8911 22.8141 19.8422Z" fill="url(#paint9_linear_186_12685)"/>
<defs>
<linearGradient id="paint0_linear_186_12685" x1="0.757122" y1="7.18549" x2="3.89502" y2="5.80049" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint1_linear_186_12685" x1="0.0683757" y1="6.45663" x2="4.95709" y2="4.65585" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint2_linear_186_12685" x1="3.74977" y1="5.61078" x2="6.99856" y2="4.99635" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint3_linear_186_12685" x1="1.08758" y1="6.56755" x2="6.35001" y2="5.13373" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint4_linear_186_12685" x1="5.75052" y1="5.1715" x2="8.15412" y2="4.67253" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint5_linear_186_12685" x1="20.2012" y1="25.2217" x2="25.0896" y2="23.4209" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint6_linear_186_12685" x1="18.1131" y1="27.0433" x2="21.3617" y2="26.4289" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint7_linear_186_12685" x1="19.1071" y1="27.1458" x2="24.3701" y2="25.7117" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint8_linear_186_12685" x1="16.735" y1="25.4344" x2="19.1368" y2="24.9359" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
<linearGradient id="paint9_linear_186_12685" x1="20.8274" y1="22.1362" x2="23.9652" y2="20.7513" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
</defs>
</svg>

);

export default EngagementIcon;
