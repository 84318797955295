import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import BackTarotCard from "assets/svg/back-tarot-card.svg";
import classNames from "classnames";
import styles from "./YesOrNoPage.module.scss";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import http from "helpers/http";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const YesOrNoPage = () => {
  const [isSelectedCard, setIsCardSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ReactPixel.track("Yes Or No Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/yes-or-no",
      title: "Yes Or No Page",
    });
  }, []);

  const onSelectCard = (event: any) => {
    if (!isSelectedCard) {
      event.target.className = styles.moveUp;
      setIsCardSelected(true);
    }
  };

  const onClickOpenCard = async () => {
    const res: any = await http.get("/astro/yes-no");
    navigate("/yes-or-no-details", {
      state: { ...res.astrologicData.prediction.message },
    });
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className="position-relative height100">
          <HeaderWithBack />
          <h1 className="t-align-l">Yes or no</h1>
          {isSelectedCard ? (
            <h2 className="mt24 mb32">Ready to open?</h2>
          ) : (
            <h2 className="mt24 mb32">
                Pick <span className="gradient-pink-text">one card</span>
            </h2>
          )}
          <div
            id="cards-container"
            className={classNames(
              styles.cardsContainer,
              "flex justify-content-start position-relative",
            )}
          >
            {Array.from({ length: 8 }, (_, index) => (
              <img
                key={index}
                src={BackTarotCard}
                alt=""
                onClick={onSelectCard}
              />
            ))}
          </div>
          <div className={"text bottom-container-absolute"}>
            <DefaultButton
              text="Open the card"
              type="button"
              onClick={onClickOpenCard}
              isDisabled={!isSelectedCard}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default YesOrNoPage;
