import React, { FC } from "react";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import classNames from "classnames";

type BigCard = {
  title: string;
  iconUrl: string;
  text: string;
  btnText: string;
  onClickBtn: () => void;
  isPurchased: boolean;
  onClickSeeReport: () => void;
  purchasedBtnText: string;
};
const BigCard: FC<BigCard> = ({
  title,
  iconUrl,
  text,
  btnText,
  onClickBtn,
  isPurchased,
  onClickSeeReport,
  purchasedBtnText,
}) => {
  return (
    <ElBgContainer
      customClassNames={classNames(
        "padding-v18 padding-h18 gap-unset position-relative overflow-hidden",
        { height216: isPurchased },
      )}
    >
      <h3 className="gradient-pink-text fGolos mb8">{title}</h3>
      <img src={iconUrl} alt="" />
      <p className="brownish-gray-text font-s16 fLora t-align-l font-w500 mt12">
        {text}
      </p>
      <div className="mt16 flex i-align-self-end">
        <button
          className="linkBtn font-s13 fGolos font-w500"
          onClick={onClickBtn}
        >
          {btnText} <BigArrowRightIcon />
        </button>
      </div>
      {isPurchased && (
        <div
          className="bg-blur position-absolute full-width full-height flex flex-column i-align-c"
          style={{ zIndex: 10 }}
        >
          <div className="flex">
            <button
              className="linkBtn font-s16 fMontserrat font-w500"
              onClick={onClickSeeReport}
            >
              {purchasedBtnText} <BigArrowRightIcon />
            </button>
          </div>
        </div>
      )}
    </ElBgContainer>
  );
};

export default BigCard;
