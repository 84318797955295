import React from "react";
import DailyTips1Icon from "assets/svg/daily-tips-1";
import DailyTips2Icon from "assets/svg/daily-tips-2";
import DailyTips3Icon from "assets/svg/daily-tips-3";
import DailyTips4Icon from "assets/svg/daily-tips-4";

export const typeHoroscope = [
  {
    id: 0,
    type: "daily",
    title: "Today",
    isActive: true,
  },
  {
    id: 1,
    type: "week",
    title: "Week",
    isActive: false,
  },
  {
    id: 2,
    type: "month",
    title: "Month",
    isActive: false,
  },
  {
    id: 3,
    type: "year",
    title: "Year",
    isActive: false,
  },
];

export const dailyTips = [
  {
    icon: () => <DailyTips1Icon />,
    title: "Profession",
    text: "Align your career goals with cosmic energies. Strategic planning and networking lead to success. Embrace your strengths for professional fulfillment.",
    id: 0,
  },
  {
    icon: () => <DailyTips2Icon />,
    title: "Health",
    text: "Focus on well-being today. Listen to your body's needs and prioritize self-care.",
    id: 1,
  },
  {
    icon: () => <DailyTips3Icon />,
    title: "Luck",
    text: "A wave of positive energy surrounds you, enhancing your luck. Trust your instincts, take calculated risks, and be open to unexpected opportunities.",
    id: 2,
  },
  {
    icon: () => <DailyTips4Icon />,
    title: "Emotions",
    text: "Align your career goals with cosmic energies. Strategic planning and networking lead to success. Embrace your strengths for professional fulfillment.",
    id: 3,
  },
];

export const biorhythms = [
  {
    value: 50,
    title: "Physical",
    id: 0,
    color: "#7361A1",
  },
  {
    value: 70,
    title: "emotional",
    id: 1,
    color: "#374A82",
  },
  {
    value: 20,
    title: "intellectual",
    id: 2,
    color: "#746C20",
  },
  {
    value: 80,
    title: "average",
    id: 3,
    color: "#3F636B",
  },
];
