import React from "react";
const InstagramIcon = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.26318" width="30" height="30" rx="7" fill="#EDECF7" />
    <rect x="1.5" y="1.26318" width="30" height="30" rx="7" stroke="#D3D3D3" stroke-width="2" />
    <path d="M21.75 7.26318H11.25C9.18 7.26318 7.5 8.91077 7.5 10.9408V21.2383C7.5 23.2683 9.18 24.9159 11.25 24.9159H21.75C23.82 24.9159 25.5 23.2683 25.5 21.2383V10.9408C25.5 8.91077 23.82 7.26318 21.75 7.26318ZM24 21.2383C24 22.4519 22.9875 23.4449 21.75 23.4449H11.25C10.0125 23.4449 9 22.4519 9 21.2383V10.9408C9 9.72721 10.0125 8.73424 11.25 8.73424H21.75C22.9875 8.73424 24 9.72721 24 10.9408V21.2383Z" fill="url(#paint0_linear_7385_8431)" />
    <path d="M16.5 10.9408C13.605 10.9408 11.25 13.2504 11.25 16.0895C11.25 18.9287 13.605 21.2382 16.5 21.2382C19.395 21.2382 21.75 18.9287 21.75 16.0895C21.75 13.2504 19.395 10.9408 16.5 10.9408ZM16.5 19.7672C14.43 19.7672 12.75 18.1196 12.75 16.0895C12.75 14.0594 14.43 12.4119 16.5 12.4119C18.57 12.4119 20.25 14.0594 20.25 16.0895C20.25 18.1196 18.57 19.7672 16.5 19.7672Z" fill="url(#paint1_linear_7385_8431)" />
    <path d="M22.5 10.9409C22.5 11.3471 22.1642 11.6764 21.75 11.6764C21.3358 11.6764 21 11.3471 21 10.9409C21 10.5346 21.3358 10.2053 21.75 10.2053C22.1642 10.2053 22.5 10.5346 22.5 10.9409Z" fill="url(#paint2_linear_7385_8431)" />
    <defs>
      <linearGradient id="paint0_linear_7385_8431" x1="10.065" y1="31.977" x2="36.4944" y2="7.3663" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <linearGradient id="paint1_linear_7385_8431" x1="12.7463" y1="25.3572" x2="28.1634" y2="11.0009" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <linearGradient id="paint2_linear_7385_8431" x1="21.2137" y1="12.2648" x2="23.4162" y2="10.2139" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
    </defs>
  </svg>

);

export default InstagramIcon;
