import React, { FC, ReactNode, useState } from "react";
import styles from "./ReadMoreComponent.module.scss";
import classNames from "classnames";
import ArrowDown from "assets/svg/arrow-down.svg";
import ArrowUp from "assets/svg/arrow-up.svg"; // Add an arrow up icon for collapsing

type ReadMoreComponentProps = {
  renderText: () => ReactNode;
};

const ReadMoreComponent: FC<ReadMoreComponentProps> = ({ renderText }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div
        className={classNames(styles.textContainer)}
        style={{
          height: isExpanded ? "auto" : 68,
          overflow: "hidden", // Ensure that the content is clipped when collapsed
        }}
      >
        {renderText()}
        {!isExpanded && <div className={styles.textGradient} />}
      </div>
      <div className={styles.btnWrapper}>
        <button
          type="button"
          className={classNames("linkBtn", styles.customBtn)}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Read less" : "Read more"}
          <img src={isExpanded ? ArrowUp : ArrowDown} alt="" />
        </button>
      </div>
    </>
  );
};

export default ReadMoreComponent;
