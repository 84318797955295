import React, { useState } from "react";
import StepsTemplate from "../StepsTemplate";
import { useAppDispatch } from "store";
import { setBirthtime } from "store/slices/registration";
import DefaultButton from "components/buttons/DefaultButton";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { dates } from "utils/defaults";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import Style from "./styles.module.scss";

const BirthTimeSelect = () => {
  const [hour, setHour] = useState("");
  const [minutes, setMinutes] = useState("");
  const [ampm, setAmpm] = useState("");
  const dispatch = useAppDispatch();

  const onSelectBirthday = () => {
    ReactGA.event("birthtimeSelected");
    ReactPixel.trackCustom("birthtimeSelected");
    dispatch(setBirthtime(`${hour}:${minutes} ${ampm}`));
  };

  const updateTransformDay = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        setHour(panel.element.textContent);
      }
    });
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        if (["am", "pm"].some((el) => el === panel.element.textContent)) {
          setAmpm(panel.element.textContent);
        } else {
          setMinutes(panel.element.textContent);
        }
      }
    });
  };

  const onClickDontRemember = () => {
    ReactGA.event("defaultBirthtimeSelected");
    ReactPixel.trackCustom("defaultBirthtimeSelected");
    dispatch(setBirthtime(`01:00 pm`));
  };

  return (
    <StepsTemplate
      lightTitle="What's your "
      yellowTitle="birth time?"
      text="Including your birth time enhances astrological precision for nuanced insights."
    >
      <>
        <div
        className={Style.date_demo_wrapper}
        style={{ gap: "24px" }}
        >
          <Flicking
          className={Style.date_flicking}
          horizontal={false}
            defaultIndex={6}
            onReady={updateTransformDay}
            onMove={updateTransformDay}
          >
           <div className={Style.date_panel}>1</div>
<div className={Style.date_panel}>2</div>
<div className={Style.date_panel}>3</div>
<div className={Style.date_panel}>4</div>
<div className={Style.date_panel}>5</div>
<div className={Style.date_panel}>6</div>
<div className={Style.date_panel}>7</div>
<div className={Style.date_panel}>8</div>
<div className={Style.date_panel}>9</div>
<div className={Style.date_panel}>10</div>
<div className={Style.date_panel}>11</div>
<div className={Style.date_panel}>12</div>
            <ViewportSlot>
              <div
                className={Style.shadow_top}
                style={{
                  height: "40px",
                }}
              ></div>
              <div
                className={Style.shadow_bottom}
                style={{
                  height: "50px",
                }}
              ></div>
              <div
                className={Style.date_panel_border_1}
                style={{
                  width: "100%",
                  left: "0px",
                  top: "100px",
                }}
              />
            </ViewportSlot>
          </Flicking>
          <Flicking
            className={Style.date_flicking}
            horizontal={false}
            defaultIndex={54}
            onReady={updateTransform}
            onMove={updateTransform}
          >
         <div className={Style.date_panel}>1</div>
<div className={Style.date_panel}>2</div>
<div className={Style.date_panel}>3</div>
<div className={Style.date_panel}>4</div>
<div className={Style.date_panel}>5</div>
<div className={Style.date_panel}>6</div>
<div className={Style.date_panel}>7</div>
<div className={Style.date_panel}>8</div>
<div className={Style.date_panel}>9</div>
<div className={Style.date_panel}>10</div>
<div className={Style.date_panel}>11</div>
<div className={Style.date_panel}>12</div>
<div className={Style.date_panel}>13</div>
<div className={Style.date_panel}>14</div>
<div className={Style.date_panel}>15</div>
<div className={Style.date_panel}>16</div>
<div className={Style.date_panel}>17</div>
<div className={Style.date_panel}>18</div>
<div className={Style.date_panel}>19</div>
<div className={Style.date_panel}>20</div>
<div className={Style.date_panel}>21</div>
<div className={Style.date_panel}>22</div>
<div className={Style.date_panel}>23</div>
<div className={Style.date_panel}>24</div>
<div className={Style.date_panel}>25</div>
<div className={Style.date_panel}>26</div>
<div className={Style.date_panel}>27</div>
<div className={Style.date_panel}>28</div>
<div className={Style.date_panel}>29</div>
<div className={Style.date_panel}>30</div>
<div className={Style.date_panel}>31</div>
<div className={Style.date_panel}>32</div>
<div className={Style.date_panel}>33</div>
<div className={Style.date_panel}>34</div>
<div className={Style.date_panel}>35</div>
<div className={Style.date_panel}>36</div>
<div className={Style.date_panel}>37</div>
<div className={Style.date_panel}>38</div>
<div className={Style.date_panel}>39</div>
<div className={Style.date_panel}>40</div>
<div className={Style.date_panel}>41</div>
<div className={Style.date_panel}>42</div>
<div className={Style.date_panel}>43</div>
<div className={Style.date_panel}>44</div>
<div className={Style.date_panel}>45</div>
<div className={Style.date_panel}>46</div>
<div className={Style.date_panel}>47</div>
<div className={Style.date_panel}>48</div>
<div className={Style.date_panel}>49</div>
<div className={Style.date_panel}>50</div>
<div className={Style.date_panel}>51</div>
<div className={Style.date_panel}>52</div>
<div className={Style.date_panel}>53</div>
<div className={Style.date_panel}>54</div>
<div className={Style.date_panel}>55</div>
<div className={Style.date_panel}>56</div>
<div className={Style.date_panel}>57</div>
<div className={Style.date_panel}>58</div>
<div className={Style.date_panel}>59</div>
<div className={Style.date_panel}>60</div>

          <ViewportSlot>
              <div
                className={Style.shadow_top}
                style={{
                  height: "40px",
                }}
              ></div>
              <div
                className={Style.shadow_bottom}
                style={{
                  height: "50px",
                }}
              ></div>
              <div
                className={Style.date_panel_border_2}
                style={{
                  width: "100%",
                  left: "0px",
                  top: "100px",
                }}
              />
            </ViewportSlot>
          </Flicking>
          <Flicking
            className={Style.date_flicking}
            horizontal={false}
            onReady={updateTransform}
            onMove={updateTransform}
          >
            <div className={Style.date_panel}>am</div>
            <div className={Style.date_panel}>pm</div>

            <ViewportSlot>
              <div
                className={Style.date_panel_border_3}
                style={{
                  width: "100%",
                  top: "100px",

                  left: "0px",
                }}
              />
            </ViewportSlot>
          </Flicking>
          {/* <div className="flex position-absolute date-panel-container full-width">
            <div
              className={Style.date_panel_border_1}
              style={{
                left: "60px",
                width: "50px",
              }}
            />
            <div
              className={Style.date_panel_border_2}
              style={{
                left: "160px",
                width: "50px",
              }}
            />
            <div
              className={Style.date_panel_border_3}
              style={{
                left: "260px",
                width: "50px",
              }}
            />
          </div> */}
        </div>
        <p
          className="fRoboto gradient-pink-text font-s18 font-w400 mt16"
          style={{
            fontFamily: "Golos",
          }}
          onClick={onClickDontRemember}
        >
          I don’t remember
        </p>
        <div className={Style.bottom_container_absolute}>
          <DefaultButton
            text={"Next"}
            type="button"
            onClick={onSelectBirthday}
          />
        </div>
      </>
    </StepsTemplate>
  );
};

export default BirthTimeSelect;
