import React, { FC } from "react";
import classNames from "classnames";
import styles from "./SubscriptionCard.module.scss";
import { Subscription } from "../PaymentPage";

type SubscribeCardProps = {
  itemData: Subscription;
  isSelected: boolean;
  label?: string;
  onClick: () => void;
};
const SubscriptionCard: FC<SubscribeCardProps> = ({
  itemData,
  isSelected,
  label,
  onClick,
}) => {
  const { price, period, text, perDay } = itemData;
  return (

    <div
      onClick={onClick}
      className={classNames(
        styles.card,
        { [styles.cardSelected]: isSelected },
        "default-border flex justify-content-space-between gap10 i-align-c position-relative",
      )}
    >
      <div className={styles.leftContainer}>
        <p className="text-color-silver fRoboto font-s18 font-w700">{period}</p>
        <p className="fMontserrat font-s10 font-w600 text-uppercase text-color-silver mt8 mb8">
          {perDay}
          {/*<span className={styles.oldPrice}>{oldPrice}</span>*/}
        </p>
        <p className="fMontserrat font-s10 font-w600 text-uppercase text-color-silver">
          {text}
        </p>
      </div>
      <div
        className={classNames(styles.rightContainer, {
          [styles.rightContainerSelected]: isSelected,
        })}
      >
        <p
          className={classNames(styles.price, {
            [styles.priceSelected]: isSelected,
          })}
        >
          {price}
        </p>
        <p
          className={classNames(styles.period, {
            [styles.periodSelected]: isSelected,
          })}
        >
          {period}
        </p>
      </div>
      {label && (
        <div
          className={classNames(
            "position-absolute fMontserrat font-s8 text-uppercase",
            styles.label,
            {
              [styles.labelSelected]: isSelected,
            },
          )}
        >
          asad
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
