import React from "react";

const CorrectIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15C30 6.75 23.25 0 15 0ZM23.4 10.9L14 20.25C13.75 20.5 13.45 20.5999 13.0999 20.5999C12.75 20.5999 12.5 20.5 12.25 20.25L7.85 15.85C7.35 15.35 7.35 14.6 7.85 14.1C8.35 13.6 9.14996 13.6 9.6 14.1L13.1 17.6L21.6 9.1C22.1 8.6 22.9 8.6 23.35 9.1C23.9001 9.6 23.9001 10.4001 23.4001 10.9001L23.4 10.9Z" fill="url(#paint0_linear_2076_30247)" />
    <defs>
      <linearGradient id="paint0_linear_2076_30247" x1="-1.95" y1="39" x2="39.607" y2="24.5459" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
    </defs>
  </svg>

);

export default CorrectIcon;
