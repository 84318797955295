import React from "react";

const UserIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="28" height="28" rx="7" stroke="url(#paint0_linear_65_1221)" stroke-width="2" />
    <path d="M18.6827 11.08C18.6827 13.112 17.0347 14.76 15.0027 14.76C12.9707 14.76 11.3227 13.112 11.3227 11.08C11.3227 9.04802 12.9707 7.40002 15.0027 7.40002C17.0347 7.40002 18.6827 9.04802 18.6827 11.08ZM20.8907 22.6C21.7227 22.6 22.3467 21.816 22.1707 21C21.4347 17.72 18.4907 15.256 15.0027 15.256C11.5147 15.256 8.57071 17.736 7.83471 21C7.64274 21.816 8.26669 22.6 9.11471 22.6H20.8907Z" fill="url(#paint1_linear_65_1221)" />
    <defs>
      <linearGradient id="paint0_linear_65_1221" x1="4.275" y1="42" x2="49.1198" y2="1.04673" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <linearGradient id="paint1_linear_65_1221" x1="9.85192" y1="28.68" x2="32.4299" y2="9.14545" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
    </defs>
  </svg>

);

export default UserIcon;
