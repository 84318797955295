import React from "react";
const BrokenHeartIcon = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.7209 1.92011C16.8409 -0.239894 14.2009 1.16003 12.6007 2.56007L13.6807 3.76007L11.3206 6.12011C11.2006 6.24011 11.0407 6.36011 11.0407 6.40007L10.7206 6.68003L10.9606 7.00007C11.0006 7.04003 11.1206 7.20011 11.2006 7.32011L11.5207 7.6801L12.2007 8.48015L13.1208 9.52018L12.2407 10.4L11.4807 11.12L10.8007 11.76L10.2807 12.24L10.7208 12.7599L11.3208 13.4799L12.7208 15.0799L9.80072 17.96L11.0808 16.4801L12.2408 15.1201L11.0007 13.8401L10.2807 13.1201L9.36068 12.2401L10.8807 10.5201L11.2008 10.1601C11.3208 10.0401 11.4408 9.88019 11.4807 9.84011L11.8407 9.40007L11.4007 9.0001C11.3607 8.96014 11.2006 8.84015 11.1207 8.72015L10.7607 8.4001L8.92076 6.56015L9.9608 5.40011L10.3608 4.96007L11.0008 4.24007C10.4008 3.40007 6.68084 -1.31983 2.32094 1.88003C-2.03896 5.08013 0.840982 11.84 7.08104 16.3601C9.60104 18.1601 11.041 20 11.041 20C11.041 20 12.4411 18.1601 14.9611 16.3601C21.2008 11.84 24.0808 5.12003 19.7209 1.92023L19.7209 1.92011Z" fill="url(#paint0_linear_186_12681)"/>
<defs>
<linearGradient id="paint0_linear_186_12681" x1="-1.17229" y1="25.7658" x2="27.9038" y2="14.4101" gradientUnits="userSpaceOnUse">
<stop stop-color="#5784F8"/>
<stop offset="1" stop-color="#F5B6FF"/>
</linearGradient>
</defs>
</svg>

);

export default BrokenHeartIcon;
