import React from "react";
const ArrowRight = () => (
  <svg width="5" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7458_3)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5764 2.98959L0.953651 0.357844C0.800956 0.214134 0.567421 0.214134 0.4237 0.357844C0.27999 0.501554 0.27999 0.744074 0.4237 0.887794L2.786 3.25009L0.4237 5.6124C0.27999 5.7651 0.27999 5.9986 0.4237 6.14235C0.567411 6.28605 0.800946 6.28605 0.953651 6.14235L3.5764 3.51959C3.72011 3.3669 3.72011 3.13331 3.5764 2.98959Z" fill="url(#paint0_linear_7458_3)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_7458_3" x1="0.795896" y1="8.65016" x2="8.10813" y2="4.90149" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F86557" />
        <stop offset="1" stop-color="#FFB6C1" />
      </linearGradient>
      <clipPath id="clip0_7458_3">
        <rect width="4" height="6.5" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default ArrowRight;
