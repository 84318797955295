import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "./ChangingProgressProvider";

const CircleProgressBar = () => {
  return (
    <div style={{ width: 50, height: 50 }}>
      <ChangingProgressProvider
        values={[0, 20, 40, 60, 80, 100]}
        interval={1200}
      >
        {(percentage: any) => (
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            strokeWidth={2}
            styles={buildStyles({
              textColor: "#827677",
              textSize: "26",
              pathColor: "#d3d3d3",
            })}
          />
        )}
      </ChangingProgressProvider>
    </div>
  );
};

export default CircleProgressBar;
