import React, { FC, ReactNode } from "react";
import Sheet from "react-modal-sheet";
import "./customStyles.scss";

type CustomHeightBottomSheetProps = {
  header: () => ReactNode;
  isModalOpen: boolean;
  setOpen: (val: boolean) => void;
  content?: () => ReactNode;
  modalHeight: number;
  renderBackdrop?: boolean;
  customBackground?: string;
};
const CustomHeightBottomSheet: FC<CustomHeightBottomSheetProps> = ({
  isModalOpen,
  setOpen,
  header,
  content,
  modalHeight,
  renderBackdrop = true,
}) => {
  return (
    <>
      <Sheet
        isOpen={isModalOpen}
        onClose={() => setOpen(false)}
        disableDrag
        snapPoints={[modalHeight]}
        disableScrollLocking={true}
      >
        <Sheet.Container
          style={{backgroundColor: "#EDECF7 !important"}}
        >
          <Sheet.Header>{header()}</Sheet.Header>
          {content && <Sheet.Content>{content()}</Sheet.Content>}
        </Sheet.Container>
        <Sheet.Backdrop>
          {renderBackdrop && (
            <div
              style={{
                backgroundColor: "#E1DEF805",
                width: "100%",
                height: "100%",
                zIndex: 10,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", 
              }}
            />
          )}
        </Sheet.Backdrop>
      </Sheet>
    </>
  );
};

export default CustomHeightBottomSheet;
