import React, { memo } from "react";
import Logo from "assets/svg/logo";
import styles from "./TabHeader.module.scss";
import UserIcon from "assets/svg/user-icon";
import SettingsIcon from "assets/svg/settings-icon";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

// eslint-disable-next-line react/display-name
const TabHeader = memo(() => {
  const navigate = useNavigate();
  const onClickSettings = () => navigate("/settings");
  const onClickProfile = () => navigate("/profile");

  return (
    <div className={styles.container}>
      <div className={styles.containerLogo}>
        <Logo />
      </div>
      <div className={styles.rightContainer}>
        <p className={styles.userType}>basic</p>
        <div className={classNames(styles.headerBtn, styles.headerUserBtn)} onClick={onClickProfile}>
          <UserIcon />
        </div>
        <div className={styles.headerBtn} onClick={onClickSettings}>
          <SettingsIcon />
        </div>
      </div>
    </div>
  );
});

export default TabHeader;
