import React, {
  ChangeEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
} from "react";
import classNames from "classnames";

interface DefaultInputProps {
  label?: string;
  placeholder?: string;
  name: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  error?: string;
  type?: HTMLInputTypeAttribute;
  inputVal: string;
  maxlength?: number;
  errorWithoutText?: boolean;
  customStyles?: string;
}

const DefaultInput = forwardRef<HTMLInputElement, DefaultInputProps>(
  (props, ref) => {
    const {
      type,
      label,
      placeholder,
      name,
      handleChange,
      error,
      inputVal,
      errorWithoutText,
      customStyles,
    } = props;
    return (
      <>
        {label && <label htmlFor={name}>{label}</label>}
        <input
          ref={ref}
          className={
            error || errorWithoutText
              ? classNames("input input-error", customStyles)
              : classNames("input", customStyles)
          }
          type={type || "text"}
          name={name}
          placeholder={placeholder}
          value={inputVal}
          onChange={handleChange}
          
        />
        {error && <p className="errorMessage" style={{zIndex:-1}}>{error}</p>}
      </>
    );
  },
);

export default DefaultInput;

DefaultInput.displayName = "DefaultInput";
