import React from "react";
const BigArrowRightIcon = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_936_706)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36436 4.48442L1.43023 0.536796C1.20119 0.321231 0.850887 0.321231 0.635307 0.536796C0.419742 0.752362 0.419742 1.11614 0.635307 1.33172L4.17876 4.87517L0.635307 8.41864C0.419742 8.64769 0.419742 8.99794 0.635307 9.21356C0.850872 9.42911 1.20117 9.42911 1.43023 9.21356L5.36436 5.27942C5.57992 5.05038 5.57992 4.7 5.36436 4.48442Z"
        fill="#F86557"
      />
    </g>
    <defs>
      <clipPath id="clip0_936_706">
        <rect width="6" height="9.75" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BigArrowRightIcon;
