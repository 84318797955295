import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./NaturalElementSelect.module.scss";

type NaturalElementProps = {
  type: string;
  isSelected: boolean;
  icon: (isSelected: boolean) => ReactNode;
  onSelectNaturalElement: (elType: string) => void;
};

const NaturalElement: FC<NaturalElementProps> = ({
  type,
  icon,
  isSelected,
  onSelectNaturalElement,
}) => (
  <div
    className={classNames(
      styles.card,
      { [styles.cardSelected]: isSelected },
      "pointer",
    )}
    onClick={() => onSelectNaturalElement(type)}
  >
    {icon(isSelected)}
    <p className={styles.text}>{type}</p>
  </div>
);

export default NaturalElement;