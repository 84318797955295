import React from "react";
import PalmistryHandIcon from "assets/svg/palmistry-hand";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import { useNavigate } from "react-router-dom";
import Ellipse from "assets/svg/ellipse";
import { legends } from "../../../PalmReadingPage/PalmReadingPage";
import Palm from "assets/svg/Palm";

const PalmReading = ({ palmPhotoUrl }: { palmPhotoUrl: string }) => {
  const navigate = useNavigate();
  const navigateToPalmScanInstruction = () =>
    navigate("/palm-scan-instruction");

  const navigateToPalmInfo = () => navigate("/palm-reading");

  console.log("palmPhoto", palmPhotoUrl);
  return (
    <>
      {palmPhotoUrl ? (
        <div className="lavender-bg  border-radius12 default-border padding-v18 padding-h18">
          <h2 className="mb16">
            <span className="gradient-pink-text">Palm</span> Reading
          </h2>
          <div className="flex gap12">
            <div
              className="flex i-align-c overflow-hidden border-radius12 default-border position-relative"
              style={{ width: "59.567%", height: 296, margin: "0 auto" }}
            >
              <Palm />
            </div>
            <div className="flex flex-column gap4">
              {legends.map((item, index) => (
                <div
                  key={index}
                  className="flex i-align-c gap8 justify-content-start"
                >
                  <Ellipse color={item.color} />
                  <p className="text-color-brownishGray fMontserrat font-s14 font-w600">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex mt16 justify-content-end">
            <button
              className="linkBtn font-s16 fMontserrat font-w500"
              onClick={navigateToPalmInfo}
            >
              See my report <BigArrowRightIcon />
            </button>
          </div>
        </div>
      ) : (
        <div className="padding-b40 padding-t40 t-align-l">
          <h2>
            <span className="gradient-pink-text">Palm</span> Reading
          </h2>
          <p className="mt8 fLora font-s16 font-w400 text-color-brownishGray t-align-l">
            Discover the secrets hidden in your palm lines! Snap a photo of your
            palm and unlock personalized predictions about your future.
          </p>
          <ElBgContainer customClassNames="padding-v12 mt24">
            <PalmistryHandIcon />
          </ElBgContainer>
          <div className="flex mt16 justify-content-end">
            <button
              className="linkBtn font-s16 fMontserrat font-w500"
              onClick={navigateToPalmScanInstruction}
            >
              Get prediction <BigArrowRightIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PalmReading;
