import React, { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "assets/svg/leftArrow";
import { createBrowserHistory } from "history";
import { useAppSelector } from "store";
import Logo from "assets/images/logo.png";
import { getPaymentLocation } from "helpers/sessionStorageHelpers";

type HeaderWithBackProps = {
  withoutRightBtn?: boolean;
};

// eslint-disable-next-line react/display-name
const HeaderWithBack: FC<HeaderWithBackProps> = memo(({ withoutRightBtn }) => {
  const { openedTab } = useAppSelector((state) => state.tabNavigation);

  const navigate = useNavigate();
  const history = createBrowserHistory();
  const location = getPaymentLocation();

  const handleBackPress = () => {
    if (location) {
      navigate("/payment");
    } else {
      history.back();
    }
  };

  const goHome = () => {
    if (openedTab === 1) {
      navigate("/home");
    }
    if (openedTab === 2) {
      navigate("/reading");
    }
    if (openedTab === 3) {
      navigate("/compatibility");
    }
    if (openedTab === 4) {
      navigate("/birth-chart");
    }
  };

  return (
    <div className="flex i-align-s justify-content-space-between position-relative mb32">
      <button
        className="linkBtn font-s16 fMontserrat font-w500 flex i-align-c"
        onClick={handleBackPress}
      >
        <LeftArrow />
      </button>
      <img
        src={Logo}
        alt=""
        className="position-absolute absolute-left0 absolute-right0"
        style={{ margin: "0 auto" }}
      />
      {!withoutRightBtn && !location && (
        <button
          className="linkBtn font-s16 fMontserrat font-w500 flex i-align-c"
          onClick={goHome}
        >
          Home
        </button>
      )}
    </div>
  );
});

export default HeaderWithBack;
