import React, { useEffect } from "react";
import Layout from "components/Layout/Layout";
import HeaderWithBack from "components/headers/HeaderWithBack";
import { useAppDispatch, useAppSelector } from "store";
import Hands from "assets/svg/hands2.svg";
import { cardsData } from "./mockedData";
import BigCard from "./components/BigCard";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import classNames from "classnames";
import CloseBtnIcon from "assets/svg/close-btn";
import PalmReading from "assets/svg/palm-reading.svg";
import { addClassToHiddenModalDiv } from "utils/defaults";
import { closeSuccessfulPurchaseModal } from "store/slices/modals";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import HumanCard from "components/cards/HumanCard/HumanCard";
import { userData } from "constants/mockedData";
import { refreshPartnerData } from "store/slices/partnerRegistration";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const FullCompatibilityReport = () => {
  const { firstSign } = useAppSelector((state) => state.compatibility);
  const { romantic, friendship, personalConnection } = useAppSelector(
    (state) => state.purchases.purchases,
  );
  const partnerData = useAppSelector((state) => state.partnerRegistration);

  useEffect(() => {
    ReactPixel.track("Full Compatibility Report Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/full-compatibility-report",
      title: "Full Compatibility Report Page",
    });
    window.scrollTo(0, 0);
  }, []);

  const { isOpen } = useAppSelector(
    (state) => state.modals.successfulPurchaseModal,
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    addClassToHiddenModalDiv();
    window.scrollTo(0, 0);
  }, []);
  const navigateToPurchase = () => {
    navigate("/purchases");
  };
  const modalContent = () => {
    return (
      <div
        className={classNames(
          "mt24 flex flex-column i-align-c gap9 fRoboto font-s18 text-color-silver font-w400",
        )}
      >
        <p>
          Your compatibility reports are now available in
          <span className="gradient-blue-text"> your profile</span> under 'My
          Reports.' Explore the insights anytime at your convenience.
        </p>
        <img src={PalmReading} alt="" width={140} />
        <p>
          Thank you for choosing us for a deeper understanding of your
          connections!
        </p>
      </div>
    );
  };
  const headerContent = () => {
    return (
      <div className="flex i-align-s">
        <h2>
          <span className={"yellow-text"}>Congratulations</span> on your
          purchase!
        </h2>
        <div
          className="flex i-align-c pointer"
          style={{ paddingTop: 4 }}
          onClick={() => dispatch(closeSuccessfulPurchaseModal())}
        >
          <CloseBtnIcon />
        </div>
      </div>
    );
  };

  const onClickSetAnotherPartner = () => {
    dispatch(refreshPartnerData());
    navigate("/partner-details", {
      state: {
        navigateTo: -1,
      },
    });
  };

  return (
    <Layout>
      <div className="app-container">
        <div className="padding-content-h mb24">
          <HeaderWithBack />
          <h1 className="t-align-l fGolos">
            <span className="gradient-pink-text">Personalized</span> Compatibility
            Report
          </h1>
          <p className="mt16 fLora font-s18 font-w500 brownish-gray-text t-align-l">
            Discover a deeper understanding of your compatibility with someone
            special! Move beyond generic horoscopes and dive into the
            personalized realm of true connection.
          </p>
          <div className="flex gap12 mt24">
            <HumanCard
              humanData={userData}
              title={firstSign.title}
              iconUrl={firstSign.iconUrl}
              isUser
            />
            <HumanCard
              humanData={partnerData}
              iconUrl={partnerData.zodiac}
              title={partnerData.zodiacName}
            />
          </div>
        </div>
        <img src={Hands} alt={""} width="100%" />
        <div className="padding-content-h mt24 flex flex-column gap12 mb32">
          {cardsData.map((item, index) => (
            <BigCard
              purchasedBtnText={item.purchasedBtnText}
              onClickBtn={navigateToPurchase}
              onClickSeeReport={() =>
                navigate(
                  partnerData.name ? item.navigateTo : "/partner-details",
                  {
                    state: {
                      navigateTo: !partnerData.name ? item.navigateTo : -1,
                    },
                  },
                )
              }
              btnText={item.btnText}
              key={index}
              title={item.title}
              iconUrl={item.iconUrl}
              text={item.text}
              isPurchased={
                item.title.toLowerCase() === "romantic"
                  ? romantic
                  : item.title.toLowerCase() === "friendship"
                    ? friendship
                    : personalConnection
              }
            />
          ))}
        </div>
        {(!personalConnection || !romantic || !friendship) && (
          <div className="padding-content-h mt24 padding-b40">
            <DefaultButton
              style={{ zIndex: 5 }}
              text={"Unlock Insights"}
              onClick={navigateToPurchase}
            />
          </div>
        )}
        {partnerData.name && (
          <div className="flex padding-b40">
            <button
              className="linkBtn font-s13 fMontserrat font-w500"
              onClick={onClickSetAnotherPartner}
            >
              Explore Compatibility for Another Individual <BigArrowRightIcon />
            </button>
          </div>
        )}
        <CustomHeightBottomSheet
          modalHeight={442}
          content={modalContent}
          header={headerContent}
          isModalOpen={isOpen}
          setOpen={() => {
            console.log("close");
          }}
        />
      </div>
    </Layout>
  );
};

export default FullCompatibilityReport;
