import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import { useLocation, useNavigate } from "react-router-dom";
import YesIcon from "assets/svg/yes-icon.svg";
import NoIcon from "assets/svg/no-icon.svg";
import DefaultButton from "components/buttons/DefaultButton";
import Layout from "components/Layout/Layout";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const YesOrNoDetailPage = () => {
  const { card, image, yes_no, result } = useLocation().state;
  const navigate = useNavigate();

  useEffect(() => {
    ReactPixel.track("Yes Or No Details Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/yes-or-no-details",
      title: "Yes Or No Details Page",
    });
  }, []);

  return (
    <Layout>
      <div className="container">
        <div className="position-relative height100">
          <HeaderWithBack />
          <h1 className="t-align-l">Yes or no</h1>
          <h2 className="mt24 mb24">
            <span className="gradient-pink-text">{card}</span>
          </h2>

          <div style={{minHeight: 220}}>
          <img
            src={image}
            alt={""}
            width={"35.8%"}
            style={{ borderRadius: 10 }}
          />
          </div>

          <div className="flex gap8 justify-content-start i-align-c mt24">
            {yes_no === "YES" ? (
              <>
                <img src={YesIcon} alt={""} />
                <h2>Yes</h2>
              </>
            ) : (
              <>
                  <img src={NoIcon} alt={""} width={20} height={20} />
                <h2>No</h2>
              </>
            )}
          </div>
          <p className="brownish-gray-text t-align-l fLora font-w400 mt16 mb32">
            {result}
          </p>
          <div className="padding-b40">
            <DefaultButton
              text="Open another card"
              onClick={() => navigate("/yes-or-no")}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default YesOrNoDetailPage;
