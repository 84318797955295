import React from "react";
import NavSunIcon from "assets/svg/nav-sun";
import NavPredictionsIcon from "assets/svg/nav-predictions";
import NavHeartIcon from "assets/svg/nav-heart";
import NavZodiacIcon from "assets/svg/nav-zodiac";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { setOpenedTab } from "store/slices/tabNavigation";

const CustomBottomNavigation = () => {
  const { openedTab } = useAppSelector((state) => state.tabNavigation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const bottomTabs = [
    { navigateTo: "/home", id: 1, tabName: "today", tabIcon: NavSunIcon },
    {
      navigateTo: "/reading",
      id: 2,
      tabName: "readings",
      tabIcon: NavPredictionsIcon,
    },
    {
      navigateTo: "/compatibility",
      id: 3,
      tabName: "compatibility",
      tabIcon: NavHeartIcon,
    },
    {
      navigateTo: "/birth-chart",
      id: 4,
      tabName: "birth chart",
      tabIcon: NavZodiacIcon,
    },
  ];

  const onTabPress = (path: string, id: number) => {
    dispatch(setOpenedTab({ id }));
    navigate(path);
  };

  return (
    <div className="bottom-nav">
      {bottomTabs.map((tab) => (
        <div
          key={tab.id}
          className="bottom-nav-item-container"
          onClick={() => onTabPress(tab.navigateTo, tab.id)}
        >
          <div className={`bottom-nav-item-border ${openedTab === tab.id ? "active" : ""
            }`}>
            <div
              className={`bottom-nav-item`}
            >
              {tab.tabIcon()}
            </div>
          </div>
          <p>{tab.tabName}</p>
        </div>
      ))}
      {/*<div*/}
      {/*  className="bottom-nav-item-container"*/}
      {/*  onClick={() => {*/}
      {/*    navigate("/home");*/}
      {/*    handleItemClick(0);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className={`bottom-nav-item ${activeItem === 0 ? "active" : ""}`}>*/}
      {/*    <NavSunIcon />*/}
      {/*  </div>*/}
      {/*  <p>today</p>*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="bottom-nav-item-container"*/}
      {/*  onClick={() => {*/}
      {/*    navigate("/reading");*/}
      {/*    handleItemClick(1);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className={`bottom-nav-item ${activeItem === 1 ? "active" : ""}`}>*/}
      {/*    <NavPredictionsIcon />*/}
      {/*  </div>*/}
      {/*  <p>readings</p>*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="bottom-nav-item-container"*/}
      {/*  onClick={() => handleItemClick(2)}*/}
      {/*>*/}
      {/*  <div className={`bottom-nav-item ${activeItem === 2 ? "active" : ""}`}>*/}
      {/*    <NavHeartIcon />*/}
      {/*  </div>*/}
      {/*  <p>compatibility</p>*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="bottom-nav-item-container"*/}
      {/*  onClick={() => handleItemClick(3)}*/}
      {/*>*/}
      {/*  <div className={`bottom-nav-item ${activeItem === 3 ? "active" : ""}`}>*/}
      {/*    <NavZodiacIcon />*/}
      {/*  </div>*/}
      {/*  <p>birth chart</p>*/}
      {/*</div>*/}
    </div>
  );
};

export default CustomBottomNavigation;
