import React, { FC } from "react";
import styles from "./ZodiacCard.module.scss";
import classNames from "classnames";

type ZodiacCardProps = {
  isUserZodiac: boolean;
  title: string;
  iconUrl: string;
  isSecondSign?: boolean;
  customStyles?: any;
};
const ZodiacCard: FC<ZodiacCardProps> = ({
  title,
  isUserZodiac,
  iconUrl,
  isSecondSign = false,
  customStyles,
}) => {
  const titleClassNames = classNames(styles.title, {
    [styles.selectedTitle]: isUserZodiac,
  });

  const circleClassNames = classNames(styles.circle, {
    [styles.secondBlueCircle]: isSecondSign,
  });

  return (
    <div
      className={classNames(styles.container, {
        [`${customStyles}`]: customStyles,
      })}
    >
      <p className={`${titleClassNames} font-s10 font-w600 `}>{title}</p>
      <div className={circleClassNames}>
        <img src={iconUrl} alt="" />
      </div>
    </div>
  );
};

export default ZodiacCard;
