import React, { FC } from "react";
import CustomZodiacCard from "components/CustomZodiacCard/CustomZodiacCard";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import classNames from "classnames";
import NounQuestion from "assets/svg/noun-question.svg";

type HumanCardProps = {
  title?: string;
  iconUrl?: string;
  isUser?: boolean;
  humanData: {
    name: string;
    gender: string;
    birthday: string;
    birthtime: string;
    birthplace: string;
  };
};
const HumanCard: FC<HumanCardProps> = ({
  title,
  iconUrl,
  isUser = false,
  humanData,
}) => {
  const { name, gender, birthtime, birthday, birthplace } = humanData;

  const isHumanDataSet = !!name;

  return (
    <div className="default-border border-radius12 full-width padding-v16 padding-h12 gap8 i-align-unset">
      <CustomZodiacCard
        title={title ? title : "zodiac sign"}
        iconUrl={iconUrl ? iconUrl : NounQuestion}
        cardWidth={50}
        cardHeight={50}
        iconWidth={28}
        iconHeight={28}
        customTitleStyles={classNames("font-s8", {["gradient-pink-text"]: isUser})}
      />
      <div className="flex flex-column gap4 i-align-s mt12">
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="gradient-pink-text font-s8" style={{marginRight: 4}}>
            Name:
          </span>
          <span className="font-s8 brownish-gray-text">
            {isHumanDataSet ? name : "**** ******************"}
          </span>
        </p>
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="gradient-pink-text font-s8" style={{marginRight: 4}}>
            gender:
          </span>
          <span className="font-s8 brownish-gray-text">
            {isHumanDataSet ? gender : "*****"}
          </span>
        </p>
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="gradient-pink-text font-s8" style={{marginRight: 4}}>
            Birthdate:
          </span>
          <span className="font-s8 brownish-gray-text">
            {isHumanDataSet ? birthday : "** ** ****"}
          </span>
        </p>
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="gradient-pink-text font-s8" style={{marginRight: 4}}>
            birthtime:
          </span>
          <span className="font-s8 brownish-gray-text">
            {isHumanDataSet ? birthtime : "** **"}
          </span>
        </p>
        <p className="flex gap4 t-align-l i-align-s font-w600 text-uppercase">
          <span className="gradient-pink-text font-s8" style={{marginRight: 4}}>
            birthplace:
          </span>
          <span className="font-s8 brownish-gray-text">
            {isHumanDataSet ? birthplace : "********"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default HumanCard;
