import React, { FC } from "react";

const Logo: FC = () => (
  <svg width="95" height="30" viewBox="0 0 95 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M82.9297 13.0901C82.9297 11.9911 83.8206 11.1001 84.9197 11.1001V19.1001H82.9297V13.0901Z" fill="#827677" />
    <path d="M28.1042 25.9999V22.7519L28.0202 22.2199V16.7879C28.0202 15.5372 27.6655 14.5759 26.9562 13.9039C26.2655 13.2319 25.2295 12.8959 23.8482 12.8959C22.8962 12.8959 21.9909 13.0546 21.1322 13.3719C20.8744 13.4672 20.6284 13.5717 20.3943 13.6854C19.5811 14.0804 18.5194 13.9218 18.0522 13.1479C18.7989 12.5132 19.6949 12.0279 20.7402 11.6919C21.7855 11.3372 22.8869 11.1599 24.0442 11.1599C25.9482 11.1599 27.4135 11.6359 28.4402 12.5879C29.4855 13.5212 30.0082 14.9492 30.0082 16.8719V25.9999H28.1042ZM23.0362 26.1399C21.9349 26.1399 20.9735 25.9626 20.1522 25.6079C19.3495 25.2346 18.7335 24.7306 18.3042 24.0959C17.8749 23.4426 17.6602 22.6959 17.6602 21.8559C17.6602 21.0906 17.8375 20.3999 18.1922 19.7839C18.5655 19.1492 19.1629 18.6452 19.9842 18.2719C20.8242 17.8799 21.9442 17.6839 23.3442 17.6839H28.4122V19.1679H23.4002C21.9815 19.1679 20.9922 19.4199 20.4322 19.9239C19.8909 20.4279 19.6202 21.0532 19.6202 21.7999C19.6202 22.6399 19.9469 23.3119 20.6002 23.8159C21.2535 24.3199 22.1682 24.5719 23.3442 24.5719C24.4642 24.5719 25.4255 24.3199 26.2282 23.8159C27.0495 23.2932 27.6469 22.5466 28.0202 21.5759L28.4682 22.9479C28.0949 23.9186 27.4415 24.6932 26.5082 25.2719C25.5935 25.8506 24.4362 26.1399 23.0362 26.1399Z" fill="#827677" />
    <path d="M5.26341 15.8919H15.3994V17.6839H5.26341V23.9279C5.26341 25.0722 4.33574 25.9999 3.19141 25.9999V6.3999H16.6314V8.1919H5.26341V15.8919Z" fill="#827677" />
    <path d="M5.25375 24.0737C5.24736 25.1451 4.37364 26.0085 3.30222 26.0021L3.19222 26.0015C3.19861 24.93 4.07234 24.0667 5.14375 24.0731L5.25375 24.0737Z" fill="url(#paint0_linear_7436_27496)" />
    <path d="M39.0534 26.14C37.6721 26.14 36.6081 25.7667 35.8614 25.02C35.1147 24.2733 34.7414 23.2187 34.7414 21.856V12.952H32.1094V11.3H34.7414V8.052C35.8393 8.052 36.7294 8.94206 36.7294 10.04V11.3H41.2094V12.952H36.7294V21.744C36.7294 22.6027 36.9441 23.2653 37.3734 23.732C37.8214 24.1987 38.4561 24.432 39.2774 24.432C40.1547 24.432 40.8827 24.18 41.4614 23.676L42.1614 25.104C41.7694 25.4587 41.2934 25.72 40.7334 25.888C40.1921 26.056 39.6321 26.14 39.0534 26.14Z" fill="#827677" />
    <path d="M62.4914 26V12.952H59.8594V11.3H62.4914V9.45199C62.4914 8.14532 62.8741 7.09066 63.6394 6.28799C64.4048 5.48532 65.5061 5.08398 66.9434 5.08398C67.5034 5.08398 68.0448 5.15865 68.5674 5.30798C69.0901 5.45732 69.5288 5.69065 69.8834 6.00799L69.2114 7.49199C68.9314 7.24932 68.6048 7.07199 68.2314 6.95999C67.8581 6.82932 67.4568 6.76399 67.0274 6.76399C66.1874 6.76399 65.5434 6.99732 65.0954 7.46399C64.6474 7.93066 64.4234 8.62132 64.4234 9.53599V11.3H68.9594C68.9594 12.2124 68.2197 12.952 67.3074 12.952H64.4794V26H62.4914Z" fill="#827677" />
    <path d="M90.5 5C90.5 3.89543 91.3954 3 92.5 3V11H90.5V5Z" fill="#827677" />
    <path d="M90.5 5C90.5 3.89543 91.3954 3 92.5 3C92.5 4.10457 91.6046 5 90.5 5Z" fill="url(#paint1_linear_7436_27496)" />
    <path d="M90.5 11V27H92.5V11H90.5Z" fill="#827677" />
    <path d="M77.7238 26.1398C76.4731 26.1398 75.3811 25.9065 74.4478 25.4398C73.5145 24.9731 72.7865 24.2731 72.2638 23.3398C71.7598 22.4065 71.5078 21.2398 71.5078 19.8398V11.2998H73.4958V19.6158C73.4958 21.1838 73.8785 22.3691 74.6438 23.1718C75.4278 23.9558 76.5198 24.3478 77.9198 24.3478C78.9465 24.3478 79.8331 24.1425 80.5798 23.7318C81.3451 23.3025 81.9238 22.6865 82.3158 21.8838C82.7265 21.0811 82.9318 20.1198 82.9318 18.9998V13.2878C82.9318 12.1899 83.8219 11.2998 84.9198 11.2998V25.9998H83.0158V23.3116C82.5585 24.1057 81.9332 24.7498 81.1398 25.2438C80.1691 25.8411 79.0305 26.1398 77.7238 26.1398Z" fill="#827677" />
    <path d="M82.9297 13.0901C82.9297 11.9911 83.8206 11.1001 84.9197 11.1001C84.9197 12.1991 84.0287 13.0901 82.9297 13.0901Z" fill="url(#paint2_linear_7436_27496)" />
    <g clip-path="url(#clip0_7436_27496)">
      <path d="M76 9C76 5.68629 78.6863 3 82 3H85C85 6.31371 82.3137 9 79 9H76Z" fill="url(#paint3_linear_7436_27496)" />
    </g>
    <path d="M51.728 25.98C50.1973 25.98 48.8533 25.6627 47.696 25.028C46.5386 24.3747 45.6333 23.488 44.98 22.368C44.3266 21.2293 44 19.932 44 18.476C44 17.02 44.308 15.732 44.924 14.612C45.5586 13.492 46.4173 12.6147 47.5 11.98C48.6013 11.3267 49.8333 11 51.196 11C52.5773 11 53.8 11.3173 54.864 11.952C55.9466 12.568 56.796 13.4453 57.412 14.584C58.028 15.704 58.336 17.0013 58.336 18.476C58.336 18.5693 58.3266 18.672 58.308 18.784C58.308 18.8773 58.308 18.98 58.308 19.092H45.512V17.608H57.244L56.46 18.196C56.46 17.132 56.2266 16.1893 55.76 15.368C55.312 14.528 54.696 13.8747 53.912 13.408C53.128 12.9413 52.2226 12.708 51.196 12.708C50.188 12.708 49.2826 12.9413 48.48 13.408C47.6773 13.8747 47.052 14.528 46.604 15.368C46.156 16.208 45.932 17.1693 45.932 18.252V18.56C45.932 19.68 46.1746 20.6693 46.66 21.528C47.164 22.368 47.8546 23.0307 48.732 23.516C49.628 23.9827 50.6453 24.216 51.784 24.216C52.68 24.216 53.5106 24.0573 54.276 23.74C54.5274 23.6382 54.7674 23.5192 54.9958 23.3829C55.7681 22.9218 56.8218 22.8933 57.412 23.572C56.7586 24.356 55.9373 24.9533 54.948 25.364C53.9773 25.7747 52.904 25.98 51.728 25.98Z" fill="#827677" />
    <path d="M67.3984 12.9C67.3984 12.0163 68.1148 11.3 68.9984 11.3V11.35C68.9984 12.2336 68.2821 12.95 67.3984 12.95V12.9Z" fill="url(#paint4_linear_7436_27496)" />
    <path d="M54.947 23.4283C55.6666 22.7831 56.773 22.8434 57.4183 23.563C56.6987 24.2082 55.5923 24.1479 54.947 23.4283Z" fill="url(#paint5_linear_7436_27496)" />
    <path d="M34.7397 8.04222C35.7939 8.00326 36.68 8.82623 36.719 9.88038L36.726 10.0702C35.6719 10.1092 34.7857 9.28624 34.7468 8.23209L34.7397 8.04222Z" fill="url(#paint6_linear_7436_27496)" />
    <path d="M19.9346 13.8947C19.2092 14.2313 18.3498 13.8917 18.0505 13.1501L18.1063 13.1104C18.9415 12.516 20.0999 12.708 20.6988 13.54L19.9346 13.8947Z" fill="url(#paint7_linear_7436_27496)" />
    <defs>
      <linearGradient id="paint0_linear_7436_27496" x1="5.39047" y1="23.4925" x2="2.57994" y2="24.5065" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_7436_27496" x1="90.37" y1="5.6" x2="93.1405" y2="4.63639" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint2_linear_7436_27496" x1="82.8003" y1="13.6871" x2="85.557" y2="12.7283" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_7436_27496" x1="75.415" y1="10.8" x2="86.4002" y2="5.06879" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint4_linear_7436_27496" x1="67.2944" y1="13.445" x2="69.5252" y2="12.6926" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint5_linear_7436_27496" x1="54.4802" y1="23.694" x2="56.7263" y2="24.9361" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint6_linear_7436_27496" x1="34.1621" y1="7.92698" x2="35.3543" y2="10.7297" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <linearGradient id="paint7_linear_7436_27496" x1="21.3589" y1="13.1968" x2="19.0823" y2="11.4891" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5784F8" />
        <stop offset="1" stop-color="#F5B6FF" />
      </linearGradient>
      <clipPath id="clip0_7436_27496">
        <rect width="9" height="6" fill="white" transform="translate(76 3)" />
      </clipPath>
    </defs>
  </svg>

);

export default Logo;
