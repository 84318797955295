import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import styles from "./LoveCompatibilitySelectPage.module.scss";
import Layout from "components/Layout/Layout";
import ZodiacCard from "./components/ZodiacCard/ZodiacCard";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import http from "../../helpers/http";
import { setCompatibilityResult } from "../../store/slices/compatibility";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const LoveCompatibilitySelectPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { zodiacsArray } = useAppSelector((state) => state.compatibility);

  const [firstSigns, setFirstSigns] = useState(
    zodiacsArray.map((zodiac) => ({ ...zodiac })),
  );
  const [secondSigns, setSecondSigns] = useState(
    zodiacsArray.map((zodiac) => ({ ...zodiac })),
  );

  useEffect(() => {
    ReactPixel.track("Love Compatibility Select Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/love-compatibility-select",
      title: "Love Compatibility Select Page",
    });
  }, []);

  const [selectedItems, setSelectedItems] = useState<{
    firstSign: any;
    lastSign: any;
  }>({
    firstSign: null,
    lastSign: null,
  });

  const toggleFirstSigns = (chosenZodiacId: number) => {
    const newArray = [...firstSigns];
    const selectedZodiac = newArray.find(
      (zodiac: any) => zodiac.id === chosenZodiacId,
    );
    if (selectedZodiac) {
      newArray.forEach((zodiac) => (zodiac.isSelected = false));
      selectedZodiac.isSelected = true;
      setSelectedItems({ ...selectedItems, firstSign: selectedZodiac });
    }
    setFirstSigns(newArray);
  };

  const toggleSecondSigns = (chosenZodiacId: number) => {
    const newArray = [...secondSigns];
    const selectedZodiac = newArray.find(
      (zodiac: any) => zodiac.id === chosenZodiacId,
    );
    if (selectedZodiac) {
      newArray.forEach((zodiac) => (zodiac.isSelected = false));
      selectedZodiac.isSelected = true;
      setSelectedItems({ ...selectedItems, lastSign: selectedZodiac });
    }
    setSecondSigns(newArray);
  };

  const onCompareBtnClick = async () => {
    if (selectedItems.lastSign !== null && selectedItems.firstSign !== null) {
      const res: any = await http.post("/astro/sign-compatibility", {
        sign_1: selectedItems.firstSign.title.toLowerCase(),
        sign_2: selectedItems.lastSign.title.toLowerCase(),
      });

      console.log("resss", res);
      dispatch(setCompatibilityResult(res.astrologicData.prediction.message));
      navigate("/love-compatibility-result", { state: { ...selectedItems } });
    }
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <div className={styles.content}>
            <h1>
              <span className={"gradient-pink-text fGolos"}>Love</span> Compatibility
            </h1>
            <p className={styles.subtitle}>
              Select two zodiac signs below to explore and compare their
              compatibility.
            </p>
            <div className={styles.section}>
              <h3 className="fGolos">Select the first sign</h3>
              <div className={styles.iconsContainer}>
                {firstSigns.map((item) => (
                  <ZodiacCard
                    isUserZodiac={item.isUserZodiac}
                    onClick={toggleFirstSigns}
                    isSelected={item.isSelected}
                    key={item.id}
                    title={item.title}
                    id={item.id}
                    iconUrl={item.iconUrl}
                  />
                ))}
              </div>
            </div>
            <div className={styles.section}>
              <h3>Select the second sign</h3>
              <div className={styles.iconsContainer}>
                {secondSigns.map((item) => (
                  <ZodiacCard
                    isUserZodiac={item.isUserZodiac}
                    onClick={toggleSecondSigns}
                    isSelected={item.isSelected}
                    key={item.id}
                    title={item.title}
                    id={item.id}
                    iconUrl={item.iconUrl}
                    isSecondSigns
                  />
                ))}
              </div>
            </div>
          </div>
          <div className={"text bottom-container-absolute"}>
            <DefaultButton
              isDisabled={
                selectedItems.lastSign === null ||
                selectedItems.firstSign === null
              }
              text="Compare Signs"
              type="button"
              onClick={onCompareBtnClick}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoveCompatibilitySelectPage;
