import React, { FC } from "react";
import styles from "./TabComponent.module.scss";
import classNames from "classnames";

type TabComponentProps = {
  imgUrl: string;
  coffeeCupName: string;
  description: string;
};
const TabComponent: FC<TabComponentProps> = ({
  imgUrl,
  coffeeCupName,
  description,
}) => {
  return (
    <div
      className={classNames(
        "mt24 flex flex-column i-align-c gap24",
        styles.container,
      )}
    >
      <div className={styles.imgContainer}>
        <img src={imgUrl} alt={""} width={"100%"} />
      </div>
      <div className={"gap16 flex flex-column i-align-l t-align-l"}>
        <h2 className="fGolos">{coffeeCupName}</h2>
        <p className={"fLora font-w500 brownish-gray-text"}>{description}</p>
      </div>
    </div>
  );
};

export default TabComponent;
