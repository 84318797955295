import React, { useEffect, useState } from "react";
import styles from "./ReadingPage.module.scss";
import Layout from "components/Layout/Layout";
import FlippedTarotCard from "components/FlippedTarotCard/FlippedTarotCard";
import classNames from "classnames";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import CoffeeCupIcon from "assets/svg/coffee-cup-reading";
import YesNoIcon from "assets/svg/yes-or-no.svg";
import YesNoLeftIcon from "assets/svg/yes-or-no-left-side.svg";
import YesNoRightIcon from "assets/svg/yes-or-no-right-side.svg";
import CustomBottomNavigation from "components/bottomNavigation/CustomBottomNavigation";
import TabHeader from "components/headers/tabHeader/TabHeader";
import OpenedCookieWithStars from "assets/svg/opened-cookie-with-stars";
import PalmReading from "../HomePage/components/PalmReading/PalmReading";
import { useAppDispatch, useAppSelector } from "store";
import { fetchTarotReading, TarotCardData } from "store/slices/tarotReading";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const ReadingPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isCardsFlipped, setIsCardsFlipped] = useState(false);
  const [flippedCardsCount, setFlippedCardsCount] = useState(0);
  const { palmPhotoUrl } = useAppSelector((state) => state.user.userData);

  const { tarotReadingResult } = useAppSelector((state) => state.tarotReading);

  console.log("tarotReadingResult", tarotReadingResult);

  const [tarotResult, setTarotResult] =
    useState<TarotCardData[]>(tarotReadingResult);

  const { savedReading } = useAppSelector((state) => state.coffeeReading);

  useEffect(() => {
    ReactPixel.track("Reading Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/reading",
      title: "Reading Page",
    });
    window.scrollTo(0, 0);
    const fetchData = async () => await dispatch(fetchTarotReading()).unwrap();
    fetchData();
  }, []);

  const toggleCard = (id: string) => {
    if (flippedCardsCount !== 3) {
      const newCardsState = JSON.parse(JSON.stringify(tarotResult));
      const toggledCard = newCardsState.find(
        (item: { cardTitle: string }) => item.cardTitle === id,
      );
      if (toggledCard) {
        toggledCard.isCardFlipped = true;
        setTarotResult(newCardsState);
        setFlippedCardsCount((prev) => prev + 1);
      }
      if (flippedCardsCount === 2) {
        setIsCardsFlipped(true);
      }
    }
  };

  const toggleAllCards = () => {
    const newCardsState = JSON.parse(JSON.stringify(tarotResult));
    newCardsState.forEach((item: TarotCardData) => (item.isCardFlipped = true));
    setTarotResult(newCardsState);
    setIsCardsFlipped(true);
  };

  return (
    <Layout>
      <div className="app-container">
        <TabHeader />
        <div className="padding-content-h">
          <h1 className="t-align-l">Readings</h1>
          <div className="mt32">
            <h2 className="t-align-l">Tarot reading</h2>
            <div
              className={classNames(
                styles.cardsContainer,
                "position-relative flex mt24 i-align-c",
              )}
            >
              {tarotResult.map((card, index) => (
                <FlippedTarotCard
                  key={index}
                  card={card.card}
                  cardId={card.cardTitle}
                  isCardFlipped={card.isCardFlipped}
                  onClick={toggleCard}
                />
              ))}
            </div>
            {isCardsFlipped && (
              <div className="mt16 flex i-align-c">
                <button
                  className="linkBtn"
                  onClick={() => navigate("/tarot-reading-result")}
                >
                  See your recent result <BigArrowRightIcon />
                </button>
              </div>
            )}
            <DefaultButton
              customStyles={"mt32 mb32"}
              text={isCardsFlipped ? "Get another reading" : "Get reading"}
              onClick={() => {
                isCardsFlipped ? navigate("/tarot-reading") : toggleAllCards();
              }}
            />
          </div>
          <PalmReading palmPhotoUrl={palmPhotoUrl} />
          <div
            onClick={() => navigate("/fortune-cookie")}
            className={classNames(
              "gap24 flex i-align-c mt60 mb20 justify-content-start",
              styles.fortuneCookieContainer,
            )}
          >
            <OpenedCookieWithStars />
            <div className="flex gap8 i-align-l flex-column">
              <h2>Fortune Cookie</h2>
              <button className="linkBtn flex i-align-c fGolos font-s13">
                Open your cookie
                <BigArrowRightIcon />
              </button>
            </div>
          </div>
          <div className="flex flex-column gap24 padding-t40 padding-b40">
            <h2
              className="t-align-l pointer"
              onClick={() => navigate("/coffee-cup-reading")}
            >
              <span className="gradient-pink-text">Coffee Cup </span>Reading
            </h2>
            <div
              className="flex gap16 i-align-c mb8 pointer"
              onClick={() => navigate("/coffee-cup-reading")}
            >
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className={classNames(
                    "default-border border-radius12 flex i-align-c",
                    styles.itemContainer,
                  )}
                >
                  <CoffeeCupIcon />
                </div>
              ))}
            </div>
            {!!savedReading.near_future_title && (
              <div className={"flex i-align-c"}>
                <button
                  className="linkBtn"
                  onClick={() => navigate("/coffee-cup-reading-result")}
                >
                  See your recent result <BigArrowRightIcon />
                </button>
              </div>
            )}
            <DefaultButton
              text={"Get reading"}
              onClick={() => navigate("/coffee-cup-reading")}
            />
          </div>
          <div className="padding-b40 gradient-border-wrapper border-radius12 mb20">
            <div
              className="gradient-border-body border-radius10 padding-h18 padding-v24 flex flex-column position-relative pointer"
              onClick={() => navigate("/yes-or-no")}
            >
              <img
                src={YesNoLeftIcon}
                alt={""}
                className={classNames(
                  styles.absoluteLeft8,
                  "position-absolute absolute-left0",
                )}
              />
              <img
                src={YesNoRightIcon}
                alt={""}
                className={classNames(
                  styles.absoluteRight8,
                  "position-absolute absolute-right0",
                )}
              />
              <div className="flex flex-column gap8 i-align-c">
                <h2>Yes or no</h2>
                <img src={YesNoIcon} alt={""} width={56} height={56} />
                <button className="linkBtn flex i-align-c fGolos font-s13 font-w500">
                  Pick your card
                  <BigArrowRightIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <CustomBottomNavigation />
      </div>
    </Layout>
  );
};

export default ReadingPage;
