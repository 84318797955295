import React, { FC, ReactNode } from "react";

type StepsTemplateProps = {
  lightTitle?: string;
  yellowTitle?: string;
  text?: string;
  children: ReactNode;
  secondLightTitle?: string;
  firstYellowText?: string;
};
const StepsTemplate: FC<StepsTemplateProps> = ({
  lightTitle,
  yellowTitle,
  text,
  children,
  secondLightTitle,
  firstYellowText,
}) => (
  <div className="titleContainer">
    <h2 className="title">
      {firstYellowText && (
        <span className="yellow-text">{firstYellowText}</span>
      )}
      {lightTitle}
      {yellowTitle && <span className="gradient-pink-text">{yellowTitle}</span>}
      {secondLightTitle}
    </h2>
    <p className="text font-s16 fLora">{text}</p>
    {children}
  </div>
);

export default StepsTemplate;
